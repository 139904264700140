import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import MainLayout from "../components/layout/MainLayout";
import {Suspense} from "react";
import MenuList from "../routers/MenuList";
import Menu from "../interfaces/Menu";
import Login from "./auth/LoginIndex";
import {isAuth} from "../utils/token";
import {getRole} from "../utils/global";

const MenuRoute = (props: any) => {
    if (!isAuth()) {
        return <Redirect to="/login"/>
    }
    return (
        <MainLayout {...props}>
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    {MenuList.map((v: Menu) => {
                        if (v.subMenu) {
                            return v.subMenu.map((item: Menu, x: number) => {
                                let role = getRole(item.code as string)
                                if (role.view === false) {
                                    return <></>;
                                }
                                return <Route
                                    {...props}
                                    exact={item.exact}
                                    key={item.path}
                                    path={item.path}
                                    render={(props) => <item.views role={role} {...props} />}
                                />
                            })
                        }
                        let role = getRole(v.code as string)
                        if (role.view === false) {
                            return <></>;
                        }
                        return (
                            <Route
                                {...props}
                                exact={v.exact}
                                key={v.path}
                                path={v.path}
                                render={(props) => <v.views role={role} {...props} />}
                            />
                        )
                    })}
                    <Redirect to="/"/>
                </Switch>
            </Suspense>
        </MainLayout>
    );
}

export default function App() {
    return (
        <Router>
            <Suspense fallback={<div>Loading...</div>}>
                <Switch>
                    <Route
                        exact
                        path="/login"
                        render={(props) => <Login {...props} />}
                    />
                    <Route
                        path="/"
                        render={(props) => <MenuRoute {...props} />}
                    />
                </Switch>
            </Suspense>
        </Router>
    );
};
