import httpService from "../../../adapters/httpService";
import {URL_API} from "../../../configs";
import baseActions from "../baseActions";

const path = 'partner/tag';

export const actionType = (action: string) => {
    return {
        request: `${action}_${path}_request`.toUpperCase(),
        success: `${action}_${path}_success`.toUpperCase(),
        error: `${action}_${path}_error`.toUpperCase()
    }
}

const http = httpService();
const index = {
    ...baseActions(path, URL_API, actionType),
    fetchAll: () => {
        return (dispatch: any) => {
            dispatch({type: actionType('fetchAll').request});
            return http.get(`${URL_API}/partner/tag/list/all`, {params: {status: true}}).then(
                response => {
                    dispatch({type: actionType('fetchAll').success, response: response.data});
                    return response.data;
                },
                error => {
                    dispatch({type: actionType('fetchAll').error});
                    throw error.response;
                }
            );
        };
    },
}
export default index;
