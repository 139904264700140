import {Add, Search} from '@mui/icons-material';
import {Button, Grid, MenuItem, OutlinedInput, Select} from '@mui/material';
import {Box} from '@mui/system';
import {debounce} from 'lodash';
import {useEffect, useState} from 'react';
import ModalDelete from '../../components/modal/ModalDelete';
import DataTable from '../../components/table/DataTable';
import partner from '../../redux/actions/partner';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import FormDialog from './FormDialog';
import {columns} from './models/columns';

const PartnerIndex = ({history, role}: any) => {
    const [openForm, setOpenForm] = useState(false);
    const [openDelete, setOpenDelete] = useState(false);
    const [dataSelected, setDataSelected] = useState({});
    const dispatch = useAppDispatch();
    const data = useAppSelector((state) => state.partner);
    const companyList = useAppSelector((state) => state.company.dataAll);

    useEffect(() => {
        dispatch(partner.fetchList(data.params));
        return () => dispatch(partner.resetParams());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleAdd = () => {
        setDataSelected({});
        setOpenForm(true);
    };
    const handleEdit = (data: any) => {
        setDataSelected(data);
        setOpenForm(true);
    };
    const handleDelete = (data: any) => {
        setDataSelected(data);
        setOpenDelete(true);
    };
    const handleSearch = debounce((e) => {
        dispatch(partner.fetchList({...data.params, page: 1, key: null, value: e.target.value}));
    }, 500);
    const filterCompany = (e: any) => {
        dispatch(
            partner.fetchList({...data.params, page: 1, key: 'company_id', value: e.target.value}),
        );
    };

    return (
        <Box>
            <Box component="h1" sx={{mt: 0, fontSize: [22, 28, 32]}}>
                Partner
            </Box>
            <Grid container spacing={1}>
                <Grid item sm={2.5}>
                    <OutlinedInput
                        fullWidth
                        sx={{mb: 1, mr: 1}}
                        onChange={handleSearch}
                        placeholder="Search"
                        endAdornment={<Search color="disabled"/>}
                        size="small"
                    />
                </Grid>
                <Grid item sm={2.5}>
                    <Select size="small" displayEmpty fullWidth defaultValue={''} onChange={filterCompany}>
                        <MenuItem value="">- All Company -</MenuItem>
                        {companyList.map((item: any, idx: number) => (
                            <MenuItem key={idx} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item sm={4} display="flex">
                    {role.create && (
                        <Button
                            sx={{mb: 1}}
                            variant="contained"
                            startIcon={<Add/>}
                            onClick={() => handleAdd()}>
                            Add
                        </Button>
                    )}
                </Grid>
            </Grid>
            <DataTable
                columns={columns(role, handleEdit, handleDelete, history)}
                data={data}
                height="60vh"
                fetchList={partner.fetchList}
            />
            <FormDialog open={openForm} handleClose={() => setOpenForm(false)} data={dataSelected}/>
            <ModalDelete
                open={openDelete}
                dataSelected={dataSelected}
                handleClose={() => setOpenDelete(false)}
                deleteData={partner.deleteData}
                fetchList={partner.fetchList}
                data={data}
            />
        </Box>
    );
};

export default PartnerIndex;
