import {yupResolver} from "@hookform/resolvers/yup"
import {bool, number, object, string, array} from "yup"

export interface IProviderForm {
    name: string,
    code: string,
    status: boolean,
}

export const providerDefaultValues = {
    name: '',
    code: '',
    status: true,
}

export const providerResolver = yupResolver(
    object({
        name: string().required('Required field'),
        code: string().required('Required field'),
        status: bool()
    })
)

export interface IChannelForm {
    payment_provider_id: number,
    provider_name: string,
    payment_channel_id: string,
    code: string,
    max_transaction: string,
    sla: string,
    capability: number,
}

export const channelDefaultValues = {
    payment_provider_id: 0,
    provider_name: '',
    payment_channel_id: '',
    code: '',
    max_transaction: '',
    sla: '',
    capability: 1,
}

export const channelResolver = yupResolver(
    object({
        code: string().required('Required field'),
        payment_channel_id: number().transform(value => (isNaN(value) ? undefined : value)).required('Required field'),
        capability: number().transform(value => (isNaN(value) ? undefined : value)).required('Required field'),
        max_transaction: number().transform(value => (isNaN(value) ? 0 : value)),
        sla: number().transform(value => (isNaN(value) ? 0 : value))
    })
)

export interface ICashInExportForm {
    start_date: any | null;
    end_date: any | null;
    payment_channel_id: string[];
    partner_id: string[];
    statuses: string[];
}

export const cashInExportFormResolver = yupResolver(
    object({
        start_date: string().notRequired(),
        end_date: string().notRequired(),
        payment_channel_id: string().notRequired(),
        partner_id: string().notRequired(),
        statuses: array().notRequired(),
    })
)

export const defaultCashInExportForm = {
    start_date: null,
    end_date: null,
    payment_channel_id: [''],
    partner_id: [''],
    statuses: [''],
}
