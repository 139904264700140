import baseInitState from "../baseInitState";
import baseReducer from "../baseReducer";
import { actionType } from "../../actions/company-provider-channel";

const initState = {
    ...baseInitState,
};

export default function reducer(state = initState, action: any) {
    switch (action.type) {
        case actionType('action').success: //example action
            return {
                ...state,
            };

        case actionType('fetchListNew').request:
            return {
                ...state,
                isLoading: true,
            };
        case actionType('fetchListNew').success:
            return {
                ...state,
                isLoading: false,
                dataList: action.response.data || [],
                pagination: {
                    currentPage: action.response?.pagination?.page || 1,
                    lastPage: action.response?.pagination?.total_page || 1,
                    count: action.response?.pagination?.total_data || 0,
                    recordPerPage: action.response?.pagination?.limit || 10,
                },
                params: action.params,
            };
        case actionType('fetchListNew').error:
            return {
                ...state,
                isLoading: false,
                dataList: [],
                pagination: {
                    currentPage: 1,
                    lastPage: 1,
                    count: 0,
                    recordPerPage: 10,
                },
                params: action.params,
            };

        default:
            return baseReducer(state, action, actionType);
    }
}
