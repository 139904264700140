import { Grid } from '@mui/material';
import { Box } from '@mui/system';

export const DivItem = ({ label, value, sx }: any) => {
  return (
    <Box sx={{ width: '100%', ...sx }}>
      <Box sx={{ fontSize: 16, fontWeight: 'bold', mb: '6px' }}>{label}</Box>
      <Box sx={{ color: 'GrayText' }}>{value}</Box>
    </Box>
  );
};

export const SpanItem = ({ label, value, widthLabel, widthValue }: any) => {
  return (
    <Grid container mb={1}>
      <Grid item sm={widthLabel || 5}>
        {label}
      </Grid>
      <Grid item sm={widthValue || 7} fontWeight="bold">
        {`: `}
        {value || '-'}
      </Grid>
    </Grid>
  );
};
