import { ExpandLess, ExpandMore } from '@mui/icons-material';
import {
  Collapse,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  Tooltip,
} from '@mui/material';
import { Box } from '@mui/system';
import { Fragment, SyntheticEvent, useEffect, useRef, useState } from 'react';
import Menu from '../../interfaces/Menu';
import { getDataCurrent } from '../../utils/token';

const SidebarItem = (props: any) => {
  const { data, history, drawerOpen, handleDrawerToggle, mobileView } = props;
  const Icon = data.icon;
  const [openSub, setOpenSub] = useState(false);
  const anchorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!drawerOpen) {
      setOpenSub(false);
    }
  }, [drawerOpen]);

  useEffect(() => {
    if (data.subMenu && !mobileView) {
      let subMenuPath = data.subMenu.map((item: any) => item.path);
      if (subMenuPath.indexOf(history.location.pathname) > -1) {
        setOpenSub(true);
      }
    }
    // eslint-disable-next-line
  }, []);

  const handleClickMenu = (path: string) => {
    if (!data.subMenu || data.path !== path) {
      if (mobileView) {
        handleDrawerToggle();
      }
      history.push(path);
      if (!drawerOpen) {
        setOpenSub(false);
      }
    } else {
      setOpenSub(!openSub);
    }
  };

  const handleClosePopover = (event: Event | SyntheticEvent) => {
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return;
    }
    setOpenSub(false);
  };

  const mapPermission = (menu: Menu) => {
    const permissionList = getDataCurrent().role_permissions || [];

    let role = permissionList.find((item: any) => item.menu_code === menu.code);
    if (menu.subMenu) {
      for (let i = 0; i < menu.subMenu.length; i++) {
        let subMenu = menu.subMenu[i];
        role = permissionList.find((item: any) => item.menu_code === subMenu?.code);
        if (role?.permission?.view === true) {
          return true;
        }
      }
      return false;
    }

    return role?.permission?.view;
  };

  if (mapPermission(data) === false) {
    return <Fragment></Fragment>;
  }

  return (
    <Box>
      <ListItemButton
        onClick={() => handleClickMenu(data.path)}
        ref={anchorRef}
        key={data.path}
        selected={history.location.pathname === data.path}>
        {data.icon &&
          (drawerOpen ? (
            <ListItemIcon>
              <Icon />
            </ListItemIcon>
          ) : (
            <Tooltip title={data.name}>
              <ListItemIcon>
                <Icon />
              </ListItemIcon>
            </Tooltip>
          ))}
        <ListItemText primary={data.name} />
        {data.subMenu && (openSub ? <ExpandLess /> : <ExpandMore />)}
      </ListItemButton>
      {data.subMenu && (
        <Fragment>
          {drawerOpen ? (
            <Collapse in={openSub} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {data.subMenu.map((v: Menu, x: number) => {
                  if (mapPermission(v) === false) {
                    return <Fragment key={x}></Fragment>;
                  }
                  return (
                    <ListItemButton
                      key={x}
                      onClick={() => handleClickMenu(v.path)}
                      selected={history.location.pathname === v.path}>
                      <ListItemIcon></ListItemIcon>
                      <ListItemText primary={v.name} />
                    </ListItemButton>
                  );
                })}
              </List>
            </Collapse>
          ) : (
            <Popover
              open={openSub}
              anchorEl={anchorRef.current}
              onClose={handleClosePopover}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}>
              <List component="div" disablePadding>
                {data.subMenu.map((v: Menu, x: number) => {
                  if (mapPermission(v) === false) {
                    return <Fragment key={x}></Fragment>;
                  }
                  return (
                    <ListItemButton
                      key={x}
                      onClick={() => handleClickMenu(v.path)}
                      selected={history.location.pathname === v.path}>
                      <ListItemText primary={v.name} />
                    </ListItemButton>
                  );
                })}
              </List>
            </Popover>
          )}
        </Fragment>
      )}
    </Box>
  );
};

export default SidebarItem;
