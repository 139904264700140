import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormProvider, useForm } from 'react-hook-form';
import { InputField, SelectField, SwitchVerticalField } from '../../components/fields/RenderField';
import { Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { Save } from '@mui/icons-material';
import { alertError, alertSuccess } from '../../utils/alert';
import partner from '../../redux/actions/partner';
import channel from '../../redux/actions/channel';
import { defaultValues, IPartnerForm, resolver } from './models/form';
import company from '../../redux/actions/company';
import TagInput from './TagInput';
import tag from '../../redux/actions/tag';

const FormDialog = ({ open, handleClose, data }: any) => {
    const methods = useForm<IPartnerForm>({ resolver });
    const [tagSelected, setTagSelected] = React.useState<any[]>([]);

    const loading = useAppSelector((state) => state.partner.isLoadingSubmit);
    const params = useAppSelector((state) => state.partner.params);
    const companyList = useAppSelector((state) => state.company.dataAll);
    const channelList = useAppSelector((state) => state.channel.dataAll);
    const dispatch = useAppDispatch();
    const companyCode = methods.watch('company_code');

    React.useEffect(() => {
        dispatch(channel.fetchAll());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onSubmit = async (value: any) => {
        const tags = tagSelected.map((item: any) => item.id);
        try {
            if (data.id) {
                await dispatch(partner.updateData(data.id, { ...value, tags }));
            } else {
                await dispatch(partner.createData({ ...value, tags }));
            }
            alertSuccess(`Partner berhasil di ${data.id ? 'update' : 'tambahkan'}`).then(() => {
                handleClose();
                dispatch(partner.fetchList(params));
            });
        } catch (error: any) {
            if (
                error?.data?.message === 'duplicate value in partner code field' ||
                error?.data?.message === 'duplicate value in code field'
            ) {
                methods.setError('partner_code', { message: 'Partner code already exists' });
            } else {
                alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
            }
        }
    };
    React.useEffect(() => {
        dispatch(company.fetchAll());
        dispatch(tag.fetchAll());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        if (companyCode !== '') {
            let companySelected = companyList.find((item: any) => item.code === companyCode);
            if (companySelected) {
                dispatch(company.fetchCompanyChannels(companySelected.id));
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyCode]);

    React.useEffect(() => {
        if (data.id && open === true) {
            methods.reset(data);
            let tags = (data?.tags || []).map((item: any) => ({ id: item.id, label: item.name }));
            setTagSelected(tags);
        } else {
            setTagSelected([]);
            methods.reset(defaultValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    return (
        <div>
            <Dialog open={open} maxWidth="md" fullWidth>
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <DialogTitle id="alert-dialog-title">{data.id ? 'Edit' : 'Add'} Partner</DialogTitle>
                    <DialogContent>
                        <FormProvider {...methods}>
                            <Grid container spacing={2}>
                                <Grid item sm={4}>
                                    <SelectField
                                        name="company_code"
                                        label="Company"
                                        options={companyList.map((data: any) => ({
                                            value: data.code,
                                            label: data.name,
                                        }))}
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    <InputField name="partner" label="Partner Name" maxLength={100} pattern={/^[\w ]*$/i} />
                                </Grid>
                                <Grid item sm={4}>
                                    <InputField name="partner_code" label="Partner Code" maxLength={5} pattern={/^[\w ]*$/i} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item sm={4}>
                                    <InputField name="phone" label="Phone" pattern={/^[0-9]*$/i}/>
                                </Grid>
                                <Grid item sm={4}>
                                    <InputField name="email" label="Email" maxLength={50} />
                                </Grid>
                                <Grid item sm={4}>
                                    <InputField name="account_receivable" label="Bank Account Name" pattern={/^[a-zA-Z ]*$/i} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item sm={4}>
                                    <SelectField
                                        name="payment_channel_id"
                                        label="Payment Channel"
                                        options={channelList.map((data: any) => ({ value: data.id, label: data.name }))}
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    <InputField
                                        name="bank_account_number"
                                        label="Bank Account Number"
                                        maxLength={16}
                                        pattern={/^[0-9]*$/i}
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    <InputField name="sla" label="SLA (day)" type="number" />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item sm={4}>
                                    <InputField
                                        name="fee_fix_value"
                                        label="Partner Fee (Fix)"
                                        type="number"
                                        min={0}
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    <InputField
                                        name="fee_percentage"
                                        label="Partner Fee (%)"
                                        type="number"
                                        min={0}
                                        max={100}
                                    />
                                </Grid>
                                <Grid item sm={4}>
                                    <SwitchVerticalField name="fee_behind" label="With Invoicing" />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                {/* <Grid item sm={4}>
                                    <TagInput {...{ tagSelected, setTagSelected }} />
                                </Grid> */}
                                <Grid item sm={4}>
                                    <SwitchVerticalField name="status" label="Aktif" />
                                </Grid>
                            </Grid>
                        </FormProvider>
                    </DialogContent>
                    <DialogActions sx={{ px: 3, pb: 3 }}>
                        <Button onClick={handleClose} variant="outlined" type="button">
                            Cancel
                        </Button>
                        <LoadingButton
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<Save />}
                            type="submit"
                            variant="contained">
                            Submit
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

export default FormDialog;
