import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormProvider, useForm } from 'react-hook-form';
import { InputField } from '../../components/fields/RenderField';
import { alertError, alertSuccess } from '../../utils/alert';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { feeLaterDefaultValues, feeLaterResolver, IFeeLaterForm } from './models/form';
import cashOut from '../../redux/actions/cash-out';
import { Box, Grid, MenuItem, Select } from '@mui/material';
import DataTable from '../../components/table/DataTable';
import { feeLaterColumns } from './models/columns';
import partner from '../../redux/actions/partner';
import { formatMoney } from '../../utils/string';

const FeeLaterDialog = ({ open, handleClose }: any) => {
  const methods = useForm<IFeeLaterForm>({ resolver: feeLaterResolver });
  const [data, setData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [partnerId, setPartnerId] = React.useState(0);
  const [companyId, setCompanyId] = React.useState(0);
  const [totalFee, setTotalFee] = React.useState(0);
  const [listSelected, setListSelected] = React.useState<any[]>([]);

  const loadingSubmit = useAppSelector((state) => state.cashOut.isLoadingSubmit);
  const params = useAppSelector((state) => state.cashOut.params);
  const companyList = useAppSelector((state) => state.company.dataAll);
  const partnerList = useAppSelector((state) => state.partner.dataAll);
  const dispatch = useAppDispatch();

  const onSubmit = async (value: any) => {
    const formData = {
      otp_code: value.otp_code,
      partner_id: partnerId,
      total_fee: totalFee,
      cash_out_transaction_id: listSelected,
    };
    try {
      await dispatch(cashOut.disburseFee(formData));
      alertSuccess(`Berhasil disburse fee`).then(() => {
        handleClose();
        dispatch(cashOut.fetchList(params));
      });
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    }
  };

  React.useEffect(() => {
    if (open) {
      setCompanyId(0);
      setPartnerId(0);
      setData([]);
      setTotalFee(0);
      setListSelected([]);
      methods.reset(feeLaterDefaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onChangeCompany = (value: any) => {
    setCompanyId(value as number);
    setPartnerId(0);
    setData([]);
    dispatch(partner.getPartnerCompany(value));
  };
  const onChangePartner = (value: any) => {
    setPartnerId(value as number);
    if (value === 0) {
      setData([]);
      return;
    }
    setLoading(true);
    cashOut
      .getList({
        page: 1,
        limit: 50,
        partner_id: value,
        status: 'reconciled',
        fee_payment_status: false,
      })
      .then((res) => {
        setData(res.payload || []);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };
  const onSelectTable = (values: any[]) => {
    setListSelected(values);
    let total = values.reduce((prev, current) => {
      let finded = data.find((item: any) => item.id === current);
      if (finded) {
        return prev + finded.admin_fee;
      }
      return prev;
    }, 0);
    setTotalFee(total);
  };

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <DialogTitle id="alert-dialog-title">Disburse Fee Later</DialogTitle>
        <DialogContent>
          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item sm={4}>
                <Select
                  size="small"
                  sx={{ mb: 1 }}
                  fullWidth
                  value={companyId}
                  onChange={(e) => onChangeCompany(e.target.value)}>
                  <MenuItem value={0}>
                    <em>- Select Company -</em>
                  </MenuItem>
                  {companyList.map((item: any, idx: number) => (
                    <MenuItem key={idx} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item sm={4}>
                <Select
                  size="small"
                  sx={{ mb: 1 }}
                  fullWidth
                  value={partnerId}
                  onChange={(e) => onChangePartner(e.target.value)}>
                  <MenuItem value={0}>
                    <em>- Select Partner -</em>
                  </MenuItem>
                  {partnerList.map((item: any, idx: number) => (
                    <MenuItem key={idx} value={item.id}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Grid>
            <DataTable
              columns={feeLaterColumns()}
              data={data}
              loading={loading}
              isPagination={false}
              height={'40vh'}
              isSelection
              onSelect={(val) => onSelectTable(val)}
            />
            <Grid container justifyContent="end" my={1}>
              <Grid item sm={2} display="flex" alignItems="center">
                Total Fee Amount:
              </Grid>
              <Grid item sm={2} textAlign="end" fontWeight="bold" fontSize={20}>
                {formatMoney(totalFee)}
              </Grid>
            </Grid>
          </Box>
          <FormProvider {...methods}>
            <Grid container>
              <Grid item sm={4}>
                <InputField name="otp_code" label="Verification Code" />
              </Grid>
            </Grid>
          </FormProvider>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button onClick={handleClose} variant="outlined" type="button">
            Cancel
          </Button>
          <LoadingButton loading={loadingSubmit} type="submit" variant="contained">
            Submit
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default FeeLaterDialog;
