import httpService from "../../../adapters/httpService";
import {URL_API} from "../../../configs";
import baseActions from "../baseActions";

const path = 'channel-mapping';

export const actionType = (action: string) => {
    return {
        request: `${action}_${path}_request`.toUpperCase(),
        success: `${action}_${path}_success`.toUpperCase(),
        error: `${action}_${path}_error`.toUpperCase()
    }
}
const index = {
    ...baseActions(path, URL_API, actionType),
    fetchList: (params?: any) => {
        const http = httpService();
        return (dispatch: any) => {
            dispatch({type: actionType('fetchList').request});
            return http.get(`${URL_API}/${path}/provider/${params.id}`, {params}).then(
                response => {
                    dispatch({type: actionType('fetchList').success, response: response.data, params});
                    return response.data;
                },
                error => {
                    dispatch({type: actionType('fetchList').error});
                    throw error.response;
                }
            );
        };
    },
    fetchAll: (id: number, additionalParams?: any) => {
        const http = httpService();
        return (dispatch: any) => {
            dispatch({type: actionType('fetchAll').request});
            return http.get(`${URL_API}/${path}/provider/${id}`, {params: {status: true, ...additionalParams}}).then(
                response => {
                    dispatch({type: actionType('fetchAll').success, response: response.data});
                    return response.data;
                },
                error => {
                    dispatch({type: actionType('fetchAll').error});
                    throw error.response;
                }
            );
        };
    },
}
export default index;
