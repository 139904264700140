import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { Grid } from '@mui/material';
import { formatDateTime } from '../../utils/time';
import { formatMoney, formatSentence } from '../../utils/string';
import { SpanItem } from '../../components/fields/ItemDetail';
import DataTableClientSide from '../../components/table/DataTableClientSide';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { useEffect, useState } from 'react';
import cashOut from '../../redux/actions/cash-out';
import { cashOutItemsColumns } from './models/columns';
import ModalDeleteBulk from '../../components/modal/ModalDeleteBulk';
import CashinInvoiceDialog from './CashinInvoiceDialog';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import CashOutExportDialog from './CashOutExportDialog';

const CashOutDetailDialog = ({ dataSelected, open, handleClose, handleRefetchDetail, handleRefetchList }: any) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.cashOut);
  const [openDelete, setOpenDelete] = useState(false)
  const [openInvoiceDialog, setOpenInvoiceDialog] = useState(false)
  const [dataInvoiceSelected, setDataInvoiceSelected] = useState<any>(null)
  const [modalExport, setModalExport] = useState<boolean>(false);

  useEffect(() => {
    if (dataSelected) dispatch(cashOut.fetchItems(dataSelected.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSelected]);

  const handleDelete = (data: any) => {
    setDataInvoiceSelected(data)
    setOpenDelete(true)
  }

  const handleRefetch = async () => {
    if (data.dataItems.length === 1) {
      handleClose();
      handleRefetchList();
      return () => { };
    } else {
      await handleRefetchDetail();
      return dispatch(cashOut.fetchItems(dataSelected.id));
    }
  };

  const handleDeleteInvoice = () => {
    const payload = {
      "cash_out_id": dataSelected.id,
      "cash_out_item_ids": [dataInvoiceSelected.id]
    }

    return cashOut.deleteInvoice(payload);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title" sx={{ fontWeight: 'bold' }}>
          Transaction Detail
        </DialogTitle>
        <DialogContent>
          <Grid container>
            <Grid item sm={6}>
              <SpanItem label="Partner Name" value={dataSelected?.partner_name} />
              <SpanItem label="Company Name" value={dataSelected?.company_name} />
              <SpanItem label="Bank Name" value={dataSelected?.partner_bank_name} />
              <SpanItem label="Bank Account Name" value={dataSelected?.partner_account_name} />
              <SpanItem label="Bank Account Number" value={dataSelected?.partner_account_number} />
              <hr />
              <SpanItem label="Subtotal Item" value={formatMoney(dataSelected?.sub_total)} />
              <SpanItem label="Transaction Fee" value={formatMoney(dataSelected?.transaction_fee)} />
              <SpanItem label="Discount" value={formatMoney(dataSelected?.total_voucher)} />
              <SpanItem label="Grand Total" value={formatMoney(dataSelected?.grand_total)} />
              <SpanItem label="Partner Fee" value={formatMoney(dataSelected?.admin_fee)} />
            </Grid>
            <Grid item sm={6}>
              <SpanItem label="Batch Number" value={dataSelected?.batch_number} />
              <SpanItem label="Cash Out Status" value={formatSentence(dataSelected?.status, '-')} />
              <SpanItem label="Payment Provider" value={dataSelected?.provider_name} />
              <SpanItem label="Payment Channel" value={dataSelected?.channel_name} />
              <SpanItem label="Payment Schedule" value={formatDateTime(dataSelected?.disbursement_schedule_at)} />
              <hr />
              <SpanItem label="Created Date" value={formatDateTime(dataSelected?.created_at)} />
              <SpanItem label="Requested By" value={dataSelected?.requested_by} />
              <SpanItem label="Requested At" value={formatDateTime(dataSelected?.requested_at)} />
              <SpanItem label="Approved By" value={dataSelected?.approved_by} />
              <SpanItem label="Approved At" value={formatDateTime(dataSelected?.approved_at)} />
              <SpanItem label="Rejected By" value={dataSelected?.rejected_by} />
              <SpanItem label="Rejected At" value={formatDateTime(dataSelected?.rejected_at)} />
              <SpanItem label="Transferred" value={formatDateTime(dataSelected?.settlement_date)} />
            </Grid>
          </Grid>
          <DataTableClientSide
            columns={cashOutItemsColumns(handleDelete, dataSelected?.status === 'ready')}
            data={data.dataItems}
            loading={data.isLoadingItems}
            showTotal
            total={data.dataItems.length}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button onClick={handleClose} variant="outlined" type="button">
            Oke
          </Button>
          {dataSelected?.status === 'ready' && (
            <Button onClick={() => setOpenInvoiceDialog(true)} variant="contained" type="button">
              ADD INVOICE
            </Button>
          )}
          <Button variant="contained" startIcon={<InsertDriveFileIcon />} onClick={() => setModalExport(true)}>
            EXPORT
          </Button>
        </DialogActions>
      </Dialog>
      <ModalDeleteBulk
        open={openDelete}
        handleClose={() => setOpenDelete(false)}
        deleteData={handleDeleteInvoice}
        fetchList={handleRefetch}
        data={data}
      />
      <CashinInvoiceDialog
        open={openInvoiceDialog}
        handleClose={() => setOpenInvoiceDialog(false)}
        partnerId={dataSelected?.partner_id || ''}
        id={dataSelected?.id || ''}
        fetchList={handleRefetch}
      />
      <CashOutExportDialog
        id={dataSelected?.id || ''}
        open={modalExport}
        handleClose={() => setModalExport(false)}
      />
    </div>
  );
};

export default CashOutDetailDialog;
