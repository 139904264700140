import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormProvider, useForm } from 'react-hook-form';
import { InputField, SwitchField } from '../../../components/fields/RenderField';
import { Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { Save } from '@mui/icons-material';
import { alertError, alertSuccess } from '../../../utils/alert';
import { defaultValues, IRoleForm, resolver } from './models/form';
import RoleTable from './RoleTable';
import { cloneDeep } from 'lodash';
import role from '../../../redux/actions/role';
import PermissionMenu from './component/PermissionMenu';

const FormDialog = ({ open, handleClose, data }: any) => {
  const methods = useForm<IRoleForm>({ resolver });

  const [loading, setLoading] = React.useState(false);
  const params = useAppSelector((state) => state.role.params);
  const permissions = useAppSelector((state) => state.role.permissions);
  const dispatch = useAppDispatch();

  const onSubmit = async (value: any) => {
    setLoading(true);

    const payload = {
      name: value.name,
      code: value.code,
      description: value.description,
      status: value.status,
    }

    try {
      if (data.id) {
        await dispatch(role.updateData(data.id, payload));
        await dispatch(role.updatePermission(data.id, value?.permissions || []));
      } else {
        await dispatch(role.createData(payload));
      }
      setLoading(false);
      alertSuccess(`Role berhasil di ${data.id ? 'update' : 'tambahkan'}`).then(() => {
        handleClose();
        dispatch(role.fetchList(params));
      });
    } catch (error: any) {
      setLoading(false);
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    }
  };

  React.useEffect(() => {
    if (permissions.length > 0) {
      const newPermissions = cloneDeep(permissions);
      methods.setValue('permissions', newPermissions);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [permissions]);

  React.useEffect(() => {
    if (data.id && open === true) {
      methods.reset(data);
    } else {
      methods.reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const currentData = methods.getValues();

  return (
    <div>
      <Dialog open={open} maxWidth="xs" fullWidth>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle id="alert-dialog-title">{data.id ? 'Edit' : 'Add'} Role</DialogTitle>
          <DialogContent>
            <FormProvider {...methods}>
              <Grid container spacing={2}>
                <Grid item sm={12}>
                  <InputField name="name" label="Role" />
                </Grid>
                <Grid item sm={12}>
                  <InputField name="code" label="Role Code" />
                </Grid>
                <Grid item sm={12}>
                  <InputField name="description" label="Description" />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={2}>
                  <SwitchField name="status" label="Status" />
                </Grid>
              </Grid>
              {currentData.permissions && currentData.permissions.map((val: any) => <PermissionMenu data={val} />)}
            </FormProvider>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button onClick={handleClose} variant="outlined" type="button">
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<Save />}
              type="submit"
              variant="contained">
              Submit
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default FormDialog;
