import { Avatar, Backdrop, Box, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import { Logout, QrCode as QrCodeIcon } from '@mui/icons-material';
import { MouseEvent, useState } from 'react';
import QRCode from 'react-qr-code';
import { useAppSelector } from '../../redux/store';
import auth from '../../redux/actions/auth';

const paperProps = {
  elevation: 0,
  sx: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1.5,
    '& .MuiAvatar-root': {
      width: 32,
      height: 32,
      ml: -0.5,
      mr: 1,
    },
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
};

export default function ProfileMenu({ history }: any) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openQR, setOpenQR] = useState(false);
  const dataQR = useAppSelector((state) => state.cashOut.dataQR);

  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <IconButton
        onClick={handleClick}
        size="small"
        sx={{ ml: 2 }}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}>
        <Avatar sx={{ width: 32, height: 32 }} />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={paperProps}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
        <MenuItem onClick={() => setOpenQR(true)}>
          <ListItemIcon>
            <QrCodeIcon fontSize="small" />
          </ListItemIcon>
          Google Auth
        </MenuItem>
        <MenuItem onClick={auth.logout}>
          <ListItemIcon>
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={openQR && !!dataQR}
        onClick={() => setOpenQR(false)}>
        <Box sx={{ p: 2, backgroundColor: 'white', display: 'flex' }}>
          <QRCode value={dataQR || ''} size={256} />
        </Box>
      </Backdrop>
    </Box>
  );
}
