import baseInitState from "../baseInitState";
import baseReducer from "../baseReducer";
import {actionType} from "../../actions/role";

const initState = {
    ...baseInitState,
    isLoadingPermission: false,
    isLoadingPermissionSubmit: false,
    permissions: []
};

export default function reducer(state = initState, action: any) {
    switch (action.type) {
        case actionType('getRolePermissions').request:
            return {
                ...state,
                isLoadingPermission: true
            };
        case actionType('getRolePermissions').success:
            return {
                ...state,
                permissions: action.response?.payload || [],
                isLoadingPermission: false
            };
        case actionType('getRolePermissions').error:
            return {
                ...state,
                isLoadingPermission: false
            };
        case actionType('updatePermission').request:
            return {
                ...state,
                isLoadingPermissionSubmit: true
            };
        case actionType('updatePermission').success:
            return {
                ...state,
                isLoadingPermissionSubmit: false
            };
        case actionType('updatePermission').error:
            return {
                ...state,
                isLoadingPermissionSubmit: false
            };
        case actionType('resetRole').success:
            return {
                ...state,
                permissions: []
            };

        default:
            return baseReducer(state, action, actionType);
    }
}
