import { yupResolver } from '@hookform/resolvers/yup';
import { number, object, ref, string } from 'yup';

export interface IPartnerForm {
  name: string;
  email: string;
  password: string;
  confirm_password: string;
  role_id: string;
  company_id: number[];
  status: boolean;
}

export const defaultValues = {
  name: '',
  email: '',
  password: '',
  confirm_password: '',
  role_id: '',
  company_id: [],
  status: true,
};

export const resolver = yupResolver(
  object({
    name: string().required('Name is required'),
    email: string().required('Email is required').email('Email must be a valid email'),
    role_id: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('Role is required'),
    password: string().when('id', {
      is: undefined,
      then: string()
        .required('Password is required')
        .required('Password is required')
        .min(6, 'Password length should be at least 6 characters'),
    }),
    confirm_password: string().when('password', {
      is: (value: any) => value !== '',
      then: string()
        .required('Confirm Password is required')
        .min(6, 'Password length should be at least 6 characters')
        .oneOf([ref('password')], 'Passwords do not match'),
    }),
  }),
);
