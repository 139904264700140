import {BorderColor, Delete} from '@mui/icons-material';
import {Grid, Tooltip} from '@mui/material';
import {GridColDef} from '@mui/x-data-grid';
import {formatCapitalize} from '../../../utils/string';

export const columns = (
    role: any,
    handleEdit: (data: any) => void,
    handleDelete: (data: any) => void,
    history?: any,
): GridColDef[] => {
    return [
        {
            field: 'logo_url',
            headerName: 'Logo',
            minWidth: 150,
            flex: 1,
            renderCell: ({ value }) => <img height={38} src={value} alt="" />
        },
        {field: 'name', headerName: 'Channel Name', minWidth: 200, flex: 1},
        {
            field: 'payment_channel_type_name',
            headerName: 'Channel Type',
            minWidth: 200,
            flex: 1,
            renderCell: ({value}) => formatCapitalize(value),
        },
        {
            field: 'status',
            headerName: 'Status',
            minWidth: 200,
            flex: 1,
            renderCell: (data) => (data.row.status ? 'Active' : 'Non Active'),
        },
        {
            field: 'action',
            headerName: 'Action',
            sortable: false,
            filterable: false,
            renderCell: (data) => (
                <Grid container>
                    {role.edit && (
                        <Tooltip title="Edit" sx={{mr: 1}} onClick={() => handleEdit(data.row)}>
                            <BorderColor fontSize="small" className="pointer" color="primary"/>
                        </Tooltip>
                    )}
                    {role.delete && (
                        <Tooltip title="Delete">
                            <Delete
                                fontSize="small"
                                className="pointer"
                                onClick={() => handleDelete(data.row)}
                                color="error"
                            />
                        </Tooltip>
                    )}
                </Grid>
            ),
        },
    ];
};
