import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import './index.css';
import App from './views/App';
import { createTheme, ThemeProvider } from '@mui/material';
import { store } from './redux/store';

const theme = createTheme({
  typography: {
    fontFamily: ['Rubik'].join(','),
  },
  palette: {
    primary: {
      main: '#7AA939',
      contrastText: '#FFFFFF',
    },
  },
  components: {
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          '.MuiFormHelperText-root': { marginLeft: 0 },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '.MuiFormHelperText-root': { marginLeft: 0 },
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          color: 'rgb(111 114 111)',
        },
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
