import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormProvider, useForm } from 'react-hook-form';
import { InputField, SelectField, SwitchField } from '../../components/fields/RenderField';
import { alertError, alertSuccess } from '../../utils/alert';
import { defaultValues, IChannelForm, resolver } from './models/form';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import channel from '../../redux/actions/channel';
import LoadingButton from '@mui/lab/LoadingButton';
import { Save } from '@mui/icons-material';
import Dropzone from '../../components/dropzone';
import { uploadImage } from '../../data';
import Swal from 'sweetalert2';
import { setNameChannelType } from './config/util';

const FormDialog = ({ open, handleClose, data }: any) => {
    const methods = useForm<IChannelForm>({ resolver })

    const loading = useAppSelector(state => state.channel.isLoadingSubmit)
    const params = useAppSelector(state => state.channel.params)
    const channelTypes = useAppSelector(state => state.channel.channelTypes)
    const dispatch = useAppDispatch()
    const [imagePreview, setImagePreview] = React.useState<Array<string>>([]);
    const [imageIsError, setImageIsError] = React.useState<boolean>(false);

    const onChangeImage = async (file: any) => {
        try {
            const formData = new FormData();
            formData.append('type', 'payment_channel');
            formData.append('file', file[0])

            const res = await uploadImage(formData);

            if (data.id) methods.reset({ ...data, logo_id: res.data.id, logo_url: res.data.url });
            else methods.reset({ ...defaultValues, logo_id: res.data.id, logo_url: res.data.url});
            
            setImagePreview([res.data.url]);
            setImageIsError(false);
        } catch (error) {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
            })
        }
    };

    const onSubmit = async (value: any) => {
        if (!value.logo_id || !value.logo_url) return setImageIsError(true);
        try {
            if (data.id) {
                await dispatch(channel.updateData(data.id, value))
            } else {
                await dispatch(channel.createData(value))
            }
            alertSuccess(`Channel berhasil di ${data.id ? 'update' : 'tambahkan'}`).then(
                () => {
                    handleClose()
                    dispatch(channel.fetchList(params))
                }
            );
        } catch (error: any) {
            alertError(error?.data?.message || 'Maaf, terjadi kesalahan')
        }
    }

    const onClose = () => {
        setImageIsError(false);
        handleClose();
    }

    React.useEffect(() => {
        dispatch(channel.getChannelType())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (data.id && open === true) {
            methods.reset(data)
            setImagePreview(!data.logo_url ? [] : [data.logo_url]);
        } else {
            methods.reset(defaultValues)
            setImagePreview(!data.logo_url ? [] : [data.logo_url]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    return (
        <div>
            <Dialog
                open={open}
                maxWidth="xs"
                fullWidth
                onClose={(event, reason) => {
                    if (reason === 'escapeKeyDown') {
                        handleClose()
                    }
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <DialogTitle id="alert-dialog-title">
                        {data.id ? 'Edit' : 'Add'} Channel
                    </DialogTitle>
                    <DialogContent>
                        <FormProvider {...methods}>
                            <div style={{ display: 'none' }} >
                                <InputField name="logo_id" />
                            </div>
                            <Dropzone
                                label="Logo Channel"
                                onChange={onChangeImage}
                                accept="image/*"
                                files={imagePreview}
                                maxSize={100000}
                                notes="JPG/JPEG/png; 1:1; Maksimal 100Kb"
                                error={imageIsError}
                            />
                            <InputField name="name" label="Channel Name" pattern={/^[\w ]*$/i} />
                            <SelectField
                                nullValue={0}
                                name="payment_channel_type_id"
                                label="Channel Type"
                                options={channelTypes.map((data: any) => ({
                                    value: data.id,
                                    label: setNameChannelType(data.payment_type)
                                }))}
                            />
                            <SwitchField name="status" label="Aktif" />
                        </FormProvider>
                    </DialogContent>
                    <DialogActions sx={{ px: 3, pb: 3 }}>
                        <Button onClick={onClose} variant="outlined" type="button">Cancel</Button>
                        <LoadingButton loading={loading} loadingPosition="start" startIcon={<Save />} type="submit"
                            variant="contained">
                            Submit
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}

export default FormDialog
