import {Box} from "@mui/system";
import {useState} from "react";
import DataTable from "../../components/table/DataTable";
import {useAppDispatch, useAppSelector} from "../../redux/store";
import CashOutHistory from "./CashOutHistory";
import CashOutDetailDialog from "./CashOutDetailDialog";
import {cashOutColumns} from "./models/columns";
import TransferDialog from "./TransferDialog";
import cashOut from "../../redux/actions/cash-out";
import ApproveDialog from "./ApproveDialog";
import RejectDialog from "./RejectDialog";
import CashOutFilter from "./CashOutFilter";
import {GridRowParams} from "@mui/x-data-grid";
import {Button, Grid, OutlinedInput} from "@mui/material";
import FeeLaterDialog from "./FeeLaterDialog";
import {Search} from "@mui/icons-material";
import {debounce} from "lodash";
import { alertError } from "../../utils/alert";

const CashOutIndex = ({history, role}: any) => {
    const [dataSelected, setDataSelected] = useState()
    const [openDetail, setOpenDetail] = useState(false)
    const [openHistory, setOpenHistory] = useState(false)
    const [openTransfer, setOpenTransfer] = useState(false)
    const [openApprove, setOpenApprove] = useState(false)
    const [openReject, setOpenReject] = useState(false)
    const [openFeeLater, setOpenFeeLater] = useState(false)
    const [type, setType] = useState('one')
    const [listSelected, setListSelected] = useState<any[]>([])
    const data = useAppSelector(state => state.cashOut)
    const dispatch = useAppDispatch()

    const handleHistory = (data: any) => {
        setDataSelected(data)
        setOpenHistory(true)
    }
    const handleRefetchDetail = async (data: any) => {
        const res = await dispatch(cashOut.fetchDetailById(data.id))
        setDataSelected(res.data)
    }
    const handleRefetchList = async () => await dispatch(cashOut.fetchList({ ...data?.params, page: 1 }));
    const handleDetail = async (data: any) => {
        try {
            await handleRefetchDetail(data);
            setOpenHistory(false)
            setOpenDetail(true)
        } catch (error: any) {
            alertError(error.data.message)
        }
    }
    const handleTransfer = (data: any) => {
        setDataSelected(data)
        setOpenHistory(false)
        setOpenDetail(false)
        setOpenTransfer(true)
    }
    const handleApprove = (data: any) => {
        setType('one')
        setDataSelected(data)
        setOpenHistory(false)
        setOpenDetail(false)
        setOpenApprove(true)
    }
    const handleBulkApprove = () => {
        setType('bulk')
        setOpenHistory(false)
        setOpenDetail(false)
        setOpenApprove(true)
    }
    const handleReject = (data: any) => {
        setDataSelected(data)
        setOpenHistory(false)
        setOpenDetail(false)
        setOpenReject(true)
    }
    const handleBulkReject = () => {
        setType('bulk')
        setOpenHistory(false)
        setOpenDetail(false)
        setOpenReject(true)
    }
    const isSelected = () => {
        let findData = data.dataList.find((item: any) => item.status === 'requested')
        return !!findData && (role.approve || role.reject)
    }
    const actionWidth = () => {
        let findData = data.dataList.find((item: any) => item.status === 'requested' || item.status === 'ready')
        return !!findData ? 250 : 100
    }
    const handleSearch = debounce((e) => {
        dispatch(cashOut.fetchList({...data.params, page: 1, key: null, value: e.target.value}));
    }, 500);

    return (
        <Box>
            <Box component="h1" sx={{mt: 0, fontSize: [22, 28, 32]}}>Cash Out Transactions</Box>
            <CashOutFilter/>
            <Grid container mb={0.5} spacing={1}>
                <Grid item sm={3}>
                    <OutlinedInput fullWidth onChange={handleSearch} placeholder="Search"
                                   endAdornment={<Search color="disabled"/>} size="small"/>
                </Grid>
                <Grid item sm={9} textAlign="end">
                    {listSelected.length > 0 && role.approve &&
                        <Button variant="contained" sx={{mb: 1, mr: 1}} onClick={handleBulkApprove}>Approve</Button>}
                    {listSelected.length > 0 && role.reject &&
                        <Button variant="contained" sx={{mb: 1, mr: 1}} color="error"
                                onClick={handleBulkReject}>Reject</Button>}
                    {role.approve &&
                        <Button variant="contained" sx={{mb: 1}} color="info" onClick={() => setOpenFeeLater(true)}>Disburse
                            Fee Later</Button>}
                </Grid>
            </Grid>
            <DataTable
                columns={cashOutColumns(role, handleHistory, handleDetail, handleTransfer, handleApprove, handleReject, actionWidth())}
                data={data}
                height="70vh"
                isSelection={isSelected()}
                fetchList={cashOut.fetchList}
                isRowSelectable={(params: GridRowParams) => params.row.status === 'requested'}
                onSelect={val => setListSelected(val)}
            />
            <CashOutDetailDialog 
                open={openDetail} 
                handleClose={() => setOpenDetail(false)}
                dataSelected={dataSelected}
                handleRefetchDetail={async () => await handleRefetchDetail(dataSelected)}
                handleRefetchList={handleRefetchList}
            />
            <TransferDialog open={openTransfer} handleClose={() => setOpenTransfer(false)} data={dataSelected}/>
            <FeeLaterDialog open={openFeeLater} handleClose={() => setOpenFeeLater(false)}/>
            <ApproveDialog
                open={openApprove}
                handleClose={() => setOpenApprove(false)}
                data={dataSelected}
                list={listSelected}
                type={type}
            />
            <RejectDialog
                open={openReject}
                handleClose={() => setOpenReject(false)}
                data={dataSelected}
                list={listSelected}
                type={type}
            />
            {openHistory && <CashOutHistory dataSelected={dataSelected}/>}
        </Box>
    )
}

export default CashOutIndex;
