import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormProvider, useForm } from 'react-hook-form';
import {
  InputDateTimeField,
  InputField,
  SelectField,
  SwitchField,
} from '../../components/fields/RenderField';
import { alertError, alertSuccess } from '../../utils/alert';
import LoadingButton from '@mui/lab/LoadingButton';
import { Save } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { ITransferForm, transferDefaultValues, transferResolver } from './models/form';
import cashOut from '../../redux/actions/cash-out';
import company from '../../redux/actions/company';
import provider from '../../redux/actions/provider';

const TransferDialog = ({ open, handleClose, data }: any) => {
  const methods = useForm<ITransferForm>({ resolver: transferResolver });
  const scheduler = methods.watch('scheduler');
  const loading = useAppSelector((state) => state.cashOut.isLoadingSubmit);
  const params = useAppSelector((state) => state.cashOut.params);
  const providerList = useAppSelector((state) => state.provider.dataAll);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(provider.fetchAll({}))
    // eslint-disable-next-line react-hooks/exhaustive-deps
}, [])

  React.useEffect(() => {
    if (data) {
      dispatch(company.fetchCompanyProviders(data.company_id, data.channel_id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const onSubmit = async (value: any) => {
    const formData = {
      provider_id: value.provider_id,
      channel_id: value.channel_id,
      scheduler: value.scheduler,
      scheduler_at: value.scheduler_at,
    };
    try {
      await dispatch(cashOut.updateData(data?.id, formData));
      alertSuccess(`Transfer berhasil`).then(() => {
        handleClose();
        dispatch(cashOut.fetchList(params));
      });
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    }
  };
  React.useEffect(() => {
    if (data?.id && open === true) {
      methods.reset(data);
    } else {
      methods.reset(transferDefaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle id="alert-dialog-title">Transfer Request</DialogTitle>
          <DialogContent>
            <FormProvider {...methods}>
              <InputField name="batch_number" label="Batch Number" disabled />
              <InputField name="channel_name" label="Payment Channel" disabled style={{ display: 'none' }}/>
              <SelectField
                nullValue={0}
                name="provider_id"
                label="Payment Provider"
                options={providerList.map((data: any) => ({
                  value: data.id,
                  label: data.name,
                }))}
              />
              {/* <SelectField
                name="channel_id"
                label="Payment Channel"
                options={providerChannelList.map((data: any) => ({ value: data.payment_channel_id, label: data.payment_channel_name }))}
              /> */}
              <SwitchField name="scheduler" label="Transfer Terjadwal" />
              {scheduler && (
                <InputDateTimeField
                  name="scheduler_at"
                  label="Transfer Date"
                  format="YYYY/MM/DD HH:mm:ss"
                  disablePast
                  PopperProps={{
                    placement: "top",
                  }}
                />
              )}
              {/* <InputField name="scheduler_at" type="datetime-local" label="Transfer Date" /> */}
            </FormProvider>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button onClick={handleClose} variant="outlined" type="button">
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<Save />}
              type="submit"
              variant="contained">
              Submit
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default TransferDialog;
