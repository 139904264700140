import { Box, CssBaseline } from '@mui/material';
import * as React from 'react';
import cashOut from '../../redux/actions/cash-out';
import { useAppDispatch } from '../../redux/store';
import Header from './Header';
import Sidebar from './Sidebar';

export default function MainLayout(props: any) {
  const [drawerOpen, setDrawerOpen] = React.useState(true);
  const [mobileView, setMobileView] = React.useState(false);
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    setMedia();
    window.addEventListener('resize', setMedia);
    dispatch(cashOut.generateQRCode());
    // eslint-disable-next-line
  }, []);

  const setMedia = () => {
    let inMedia = window.matchMedia('(max-width: 900px)');
    if (inMedia.matches) {
      setDrawerOpen(false);
      setMobileView(true);
    } else {
      setMobileView(false);
    }
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <Header {...{ handleDrawerToggle, drawerOpen, history: props.history }} />
      <Sidebar {...props} {...{ drawerOpen, handleDrawerToggle, mobileView }} />
      <Box component="main" sx={{ flexGrow: 1, px: 4, pb: 2, pt: [0, 2], mt: 8 }}>
        {props.children}
      </Box>
    </Box>
  );
}
