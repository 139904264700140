import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormProvider, useForm } from 'react-hook-form';
import { InputField, SelectField, SwitchField } from '../../components/fields/RenderField';
import { alertError, alertSuccess } from '../../utils/alert';
import { IProviderForm, providerDefaultValues, providerResolver } from './models/form';
import LoadingButton from '@mui/lab/LoadingButton';
import { ExpandMore, Key, Save } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import companyProvider from '../../redux/actions/company-provider';
import company from '../../redux/actions/company';
import provider from '../../redux/actions/provider';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

const ProviderFormDialog = ({ open, handleClose, data }: any) => {
  const methods = useForm<IProviderForm>({ resolver: providerResolver });

  const loading = useAppSelector((state) => state.companyProvider.isLoadingSubmit);
  const params = useAppSelector((state) => state.companyProvider.params);
  const companyList = useAppSelector((state) => state.company.dataAll);
  const providerList = useAppSelector((state) => state.provider.dataAll);
  const dispatch = useAppDispatch();
  const [secretTemplates, setSecretTemplates] = React.useState([]);
  const providerId = methods.watch('payment_provider_id');

  React.useEffect(() => {
    let provider = providerList.find((item: any) => item.id === providerId);
    setSecretTemplates(provider?.secret_templates || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [providerId]);

  const onSubmit = async (value: any) => {
    const bodyForm = {
      company_id: value.company_id,
      payment_provider_id: value.payment_provider_id,
      provider_secrets: '',
      status: value.status,
    };

    let secrets: { [key: string]: any } = {};
    for (let property in value.provider_secrets) {
      if (!secretTemplates.find((item: any) => item.name === property)) {
        continue;
      }
      secrets[property] = value.provider_secrets[property];
    }

    for (let property in secrets) {
      if (
        value.provider_secrets[property] !== '' ||
        data?.payment_provider_id !== bodyForm.payment_provider_id
      ) {
        bodyForm.provider_secrets = JSON.stringify(secrets);
        break;
      }
    }

    try {
      if (data?.id) {
        await dispatch(companyProvider.updateData(data?.id, bodyForm));
      } else {
        await dispatch(companyProvider.createData(bodyForm));
      }
      alertSuccess(`Company Provider berhasil di ${data?.id ? 'update' : 'tambahkan'}`).then(() => {
        handleClose();
        dispatch(companyProvider.fetchList(params));
      });
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    }
  };

  React.useEffect(() => {
    dispatch(provider.fetchAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (data?.id && open === true) {
      dispatch(company.fetchAll({ status: undefined }));
      methods.reset(data);
    } else {
      dispatch(company.fetchAll({ status: true }));
      let secrets: { [key: string]: any } = {};
      secretTemplates.map((item: any) => {
        secrets['provider_secrets.' + item.name] = '';
        return item;
      });
      methods.reset({ ...providerDefaultValues, ...secrets });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle id="alert-dialog-title">{data?.id ? 'Edit' : 'Add'} Provider</DialogTitle>
          <DialogContent>
            <FormProvider {...methods}>
              <SelectField
                disabled={!!data?.id}
                name="company_id"
                label="Company"
                options={companyList.map((item: any) => ({ value: item.id, label: item.name }))}
              />
              <SelectField
                disabled={!!data?.id}
                name="payment_provider_id"
                label="Provider"
                options={providerList.map((item: any) => ({
                  value: item.id,
                  label: `${item.name} (${item.code})`,
                }))}
              />
              <SwitchField name="status" label="Aktif" />
              {secretTemplates.length > 0 && (
                <Accordion
                  sx={{
                    border: (theme) => `1px solid ${theme.palette.action.disabled}`,
                    borderRadius: 1,
                    '&:before': { display: 'none' },
                    color: 'gray',
                  }}
                  elevation={0}
                  disableGutters>
                  <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                      minHeight: '24px',
                      px: '14px',
                      '.MuiAccordionSummary-content': { my: '8px' },
                    }}>
                    <Key sx={{ mr: 1 }} />
                    {data?.id ? 'Update' : 'Set'} secrets key
                  </AccordionSummary>
                  <AccordionDetails>
                    {secretTemplates.map((item: any, idx: number) => (
                      <InputField
                        key={idx}
                        name={'provider_secrets.' + item.name}
                        label={item.name}
                        autocomplete="off"
                        maxLength={0}
                      />
                    ))}
                  </AccordionDetails>
                </Accordion>
              )}
            </FormProvider>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button onClick={handleClose} variant="outlined" type="button">
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<Save />}
              type="submit"
              variant="contained">
              Submit
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default ProviderFormDialog;
