import { BorderColor, Delete, Visibility } from '@mui/icons-material';
import { Grid, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { formatMoney } from '../../../utils/string';

export const providerColumns = (
  role: any,
  handleDetail: (data: any) => void,
  handleEdit: (data: any) => void,
  handleDelete: (data: any) => void,
): GridColDef[] => {
  return [
    { field: 'name', headerName: 'Provider Name', flex: 1, minWidth: 150 },
    { field: 'code', headerName: 'Provider Code', flex: 1, minWidth: 150 },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 150,
      renderCell: (data) => (data.row.status ? 'Active' : 'Non Active'),
    },
    {
      field: 'action',
      headerName: 'Action',
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (data) => (
        <Grid container alignItems={'center'}>
          {role.view && (
            <Tooltip title="Detail" sx={{ mr: 1 }} onClick={() => handleDetail(data.row)}>
              <Visibility fontSize="small" className="pointer" color="primary" />
            </Tooltip>
          )}
          {role.edit && (
            <Tooltip title="Edit" sx={{ mr: 1 }} onClick={() => handleEdit(data.row)}>
              <BorderColor fontSize="small" className="pointer" color="info" />
            </Tooltip>
          )}
          {role.delete && (
            <Tooltip title="Delete">
              <Delete
                fontSize="small"
                className="pointer"
                onClick={() => handleDelete(data.row)}
                color="error"
              />
            </Tooltip>
          )}
        </Grid>
      ),
    },
  ];
};

export const channelColumns = (
  role: any,
  handleEdit: (data: any) => void,
  handleDelete: (data: any) => void,
): GridColDef[] => {
  return [
    { field: 'payment_channel_name', headerName: 'Payment Channel', flex: 1, minWidth: 150 },
    { field: 'code', headerName: 'Channel Code', flex: 1, minWidth: 220 },
    { field: 'provider_channel_code', headerName: 'Provider Channel Code', flex: 1, minWidth: 200 },
    {
      field: 'max_transaction',
      headerName: 'Max Transaction',
      flex: 1,
      minWidth: 150,
      renderCell: ({ value }) => formatMoney(value),
    },
    { field: 'sla', headerName: 'SLA (Days)', flex: 1, minWidth: 100 },
    {
      field: 'cash_in',
      headerName: 'Cash In',
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }) => (row.capability === 1 ? 'Yes' : '-'),
    },
    {
      field: 'cash_out',
      headerName: 'Cash Out',
      flex: 1,
      minWidth: 150,
      renderCell: ({ row }) => (row.capability === 2 ? 'Yes' : '-'),
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      filterable: false,
      renderCell: (data) => (
        <Grid container alignItems={'center'}>
          {role.edit && (
            <Tooltip title="Edit" sx={{ mr: 1 }} onClick={() => handleEdit(data.row)}>
              <BorderColor fontSize="small" className="pointer" color="info" />
            </Tooltip>
          )}
          {role.delete && (
            <Tooltip title="Delete">
              <Delete
                fontSize="small"
                className="pointer"
                onClick={() => handleDelete(data.row)}
                color="error"
              />
            </Tooltip>
          )}
        </Grid>
      ),
    },
  ];
};
