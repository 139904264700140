import {Box} from "@mui/system";
import {useEffect, useState} from "react";
import DataTable from "../../components/table/DataTable";
import {useAppSelector} from "../../redux/store";
import {cashInColumns} from "./models/columns";
import CashInHistory from "./CashInHistory";
import CashInDetailDialog from "./CashInDetailDialog";
import cashIn from "../../redux/actions/cash-in";
import CashInFilter from "./CashInFilter";
import { useAppDispatch } from '../../redux/store';
import { alertError } from "../../utils/alert";

const CashInIndex = ({history}: any) => {
    const dispatch = useAppDispatch();
    const [dataSelected, setDataSelected] = useState()
    const [openDetail, setOpenDetail] = useState(false)
    const [openHistory, setOpenHistory] = useState(false)
    const data = useAppSelector(state => state.cashIn)
    const loadingCompany = useAppSelector(state => state.company.isLoading)

    useEffect(() => {
        setDataSelected(undefined)
        setOpenHistory(false)
    }, [data.dataList])

    const handleHistory = (data: any) => {
        setDataSelected(data)
        setOpenHistory(true)
        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, 300)
    }

    const handleDetail = async (values: any) => {
        try {
            const { data } = await dispatch(cashIn.fetchDetailV2(values.id));
            setDataSelected({ ...values, ...data })
            setOpenHistory(false)
            setOpenDetail(true)
        } catch (error: any) {
            alertError(error?.data?.message || 'Maaf, terjadi kesalahan')
        }
    }

    return (
        <Box>
            <Box component="h1" sx={{mt: 0, fontSize: [22, 28, 32]}}>Cash In Transactions</Box>
            <CashInFilter/>
            <DataTable
                columns={cashInColumns(handleHistory, handleDetail)}
                data={data}
                height="75vh"
                fetchList={cashIn.fetchList}
                loading={loadingCompany || data.isLoading}
                showTotal
            />
            <CashInDetailDialog open={openDetail} handleClose={() => setOpenDetail(false)} dataSelected={dataSelected}/>
            {openHistory && <CashInHistory dataSelected={dataSelected}/>}
        </Box>
    )
}

export default CashInIndex;
