import baseInitState from '../baseInitState';
import baseReducer from '../baseReducer';
import { actionType } from '../../actions/partner';

const initState = {
  ...baseInitState,
};

export default function reducer(state = initState, action: any) {
  switch (action.type) {
    case actionType('getPartnerCompany').request:
      return {
        ...state,
        isLoading: true,
      };
    case actionType('getPartnerCompany').success:
      return {
        ...state,
        isLoading: false,
        dataAll: action.response.data || [],
      };
    case actionType('getPartnerCompany').error:
      return {
        ...state,
        isLoading: false,
      };
    default:
      return baseReducer(state, action, actionType);
  }
}
