import { Box } from '@mui/system';
import { useEffect } from 'react';
import DataTable from '../../components/table/DataTable';
import cashOutHistory from '../../redux/actions/cash-out-history';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { cashOutHistoryColumns } from './models/columns';

const CashOutHistory = ({ dataSelected }: any) => {
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.cashOutHistory);

  useEffect(() => {
    if (dataSelected) {
      dispatch(cashOutHistory.fetchList({ ...data.params, id: dataSelected?.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataSelected]);

  return (
    <Box>
      <Box component="h2" sx={{ textTransform: 'capitalize' }}>
        Transaction Activity Log
      </Box>
      <DataTable
        columns={cashOutHistoryColumns()}
        data={data}
        height="45vh"
        fetchList={cashOutHistory.fetchList}
      />
    </Box>
  );
};

export default CashOutHistory;
