import {yupResolver} from "@hookform/resolvers/yup"
import {number, object, string} from "yup"

export interface ICompanyForm {
    name: string,
    code: string,
    alias: string,
    parent_company_id: string,
    child: boolean,
    status: boolean,
    secrets: string,
}

export const defaultValues = {
    name: '',
    code: '',
    alias: '',
    parent_company_id: '',
    child: false,
    status: true,
    secrets: '',
}

export const resolver = yupResolver(
    object({
        name: string().required('Required field'),
        code: string().required('Required field').min(4, 'Minimum 4 characters'),
        alias: string().required('Required field'),
        parent_company_id: number().transform(value => (isNaN(value) ? undefined : value)).when('child', {
            is: true,
            then: number().transform(value => (isNaN(value) ? undefined : value)).required("Required field")
        }),
        secrets: string().required('Required field'),
    })
)
