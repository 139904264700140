import {FilterAlt, Search} from '@mui/icons-material';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import {Button, Grid, MenuItem, OutlinedInput, Select, TextField} from '@mui/material';
import {debounce} from 'lodash';
import moment from 'moment';
import {Fragment, useEffect, useState} from 'react';
import cashIn from '../../redux/actions/cash-in';
import company from '../../redux/actions/company';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import DateRangePicker, {DateRange} from '@mui/lab/DateRangePicker';
import DateAdapter from '@mui/lab/AdapterMoment';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Box from '@mui/material/Box';
import CashInExportDialog from './CashInExportDialog';

const CashInFilter = () => {
    const dispatch = useAppDispatch();
    const data = useAppSelector((state) => state.cashIn);
    const companyList = useAppSelector((state) => state.company.dataAll);
    const [companyId, setCompanyId] = useState(0);
    const [modalExport, setModalExport] = useState<boolean>(false);

    useEffect(() => {
        dispatch(company.fetchAll());
        return () => dispatch(cashIn.resetParams());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        dispatch(
            cashIn.fetchList({
                ...data.params,
                key: 'company_id',
                value: companyId !== 0 ? companyId : null,
                page: 1,
            }),
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [companyId]);

    const handleSearch = debounce((e) => {
        dispatch(cashIn.fetchList({...data.params, page: 1, key: null, value: e.target.value}));
    }, 500);

    const [dateValue, setDateValue] = useState<DateRange<Date>>([null, null]);
    const handleFilter = () => {
        if ((dateValue[0] && !dateValue[1]) || (!dateValue[0] && dateValue[1])) {
            return;
        }
        let from = dateValue[0] ? moment(dateValue[0]).format('YYYYMMDD') : '';
        let to = dateValue[1] ? moment(dateValue[1]).format('YYYYMMDD') : '';
        dispatch(cashIn.fetchList({...data.params, page: 1, from, to}));
    };

    const handleReset = () => {
        setCompanyId(0);
        setDateValue([null, null]);
        dispatch(cashIn.fetchList({...data.params, page: 1, to: null, from: null, value: null}));
    }

    return (
        <Fragment>
            <Grid container sx={{mb: 1}} spacing={1}>
                <Grid item md={2}>
                    <Select
                        size="small"
                        fullWidth
                        value={companyId}
                        onChange={(e) => setCompanyId(parseInt(e.target.value as string))}>
                        <MenuItem value={0}>- All Company -</MenuItem>
                        {companyList.map((item: any, idx: number) => (
                            <MenuItem key={idx} value={item.id}>
                                {item.name}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
                <Grid item md={7} display="flex" spacing={1}>
                    <LocalizationProvider dateAdapter={DateAdapter}>
                        <DateRangePicker
                            value={dateValue}
                            onChange={(newValue) => {
                                setDateValue(newValue);
                            }}
                            disableFuture
                            inputFormat="DD/MM/YYYY"
                            renderInput={(startProps, endProps) => (
                                <Fragment>
                                    <TextField {...startProps} label="" size="small"/>
                                    <Box sx={{mx: 1}}> to </Box>
                                    <TextField {...endProps} sx={{mr: 1}} label="" size="small"/>
                                </Fragment>
                            )}
                        />
                    </LocalizationProvider>
                    <Button variant="contained" startIcon={<FilterAlt/>} onClick={handleFilter} sx={{mr: 1}}>
                        Filter
                    </Button>
                    <Button variant="contained" color="error" startIcon={<FilterAlt/>} onClick={handleReset} sx={{mr: 1}}>
                        Reset
                    </Button>
                    <Button variant="contained" startIcon={<InsertDriveFileIcon/>} onClick={() => setModalExport(true)}>
                        Export
                    </Button>
                </Grid>
                <Grid item md={3}>
                    <OutlinedInput
                        fullWidth
                        onChange={handleSearch}
                        placeholder="Search"
                        endAdornment={<Search color="disabled"/>}
                        size="small"
                    />
                </Grid>
            </Grid>

            <CashInExportDialog
                open={modalExport}
                handleClose={() => setModalExport(false)}
            />
        </Fragment>
    );
};

export default CashInFilter;
