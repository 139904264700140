import httpService from '../../../adapters/httpService';
import { URL_API } from '../../../configs';
import { removeAll, setDataCurrent, setToken } from '../../../utils/token';

const path = 'login';

export const actionType = (action: string) => {
  return {
    request: `${action}_${path}_request`.toUpperCase(),
    success: `${action}_${path}_success`.toUpperCase(),
    error: `${action}_${path}_error`.toUpperCase(),
  };
};

const request = httpService();
const index = {
  login: (data: any) => {
    return (dispatch: any) => {
      dispatch({ type: actionType('login').request });
      return request.post(`${URL_API}/login`, data).then(
        (response) => {
          dispatch({ type: actionType('login').success, payload: response.data?.data });
          setToken(response.data?.data?.token);
          setDataCurrent(response.data?.data);
          return response.data;
        },
        (error) => {
          dispatch({ type: actionType('login').error });
          throw error.response;
        },
      );
    };
  },
  logout: () => {
    removeAll();
    window.location.href = '/login';
  },
};
export default index;
