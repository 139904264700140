import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormProvider, useForm } from 'react-hook-form';
import {
  AutocompleteField,
  InputField,
  InputPasswordField,
  SelectField,
  SwitchField,
} from '../../../components/fields/RenderField';
import { Grid } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { useAppDispatch, useAppSelector } from '../../../redux/store';
import { Save } from '@mui/icons-material';
import { alertError, alertSuccess } from '../../../utils/alert';
import { defaultValues, IPartnerForm, resolver } from './models/form';
import company from '../../../redux/actions/company';
import role from '../../../redux/actions/role';
import user from '../../../redux/actions/user';

const FormDialog = ({ open, handleClose, data }: any) => {
  const methods = useForm<IPartnerForm>({ resolver });
  const dispatch = useAppDispatch();

  const loading = useAppSelector((state) => state.partner.isLoadingSubmit);
  const params = useAppSelector((state) => state.partner.params);
  const companyList = useAppSelector<any[]>((state) => state.company.dataAll);
  const roleList = useAppSelector((state) => state.role.dataAll);
  const [selectedCompany, setSelectedCompany] = React.useState<{ label: string; value: number }[]>(
    [],
  );

  const onSubmit = async (value: any) => {
    value.company_id = selectedCompany.map((item: any) => item.value);
    try {
      if (data.id) {
        await dispatch(user.updateData(data.id, value));
      } else {
        await dispatch(user.createData(value));
      }
      alertSuccess(`User berhasil di ${data.id ? 'update' : 'tambahkan'}`).then(() => {
        handleClose();
        dispatch(user.fetchList(params));
      });
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    }
  };
  React.useEffect(() => {
    dispatch(company.fetchAll());
    dispatch(role.fetchAll());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (data.id && open === true) {
      methods.reset({ ...data, company_id: [] });
      mapCompany(data?.company_id || []);
    } else {
      methods.reset(defaultValues);
      setSelectedCompany([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const mapCompany = (items: any[]) => {
    let companies = items.reduce((prev, current) => {
      let finded = companyList.find((item: any) => item.id === current);
      if (finded) {
        prev.push({ value: finded.id, label: finded.name });
      }
      return prev;
    }, []);

    setSelectedCompany(companies);
  };

  return (
    <div>
      <Dialog open={open} maxWidth="sm" fullWidth>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <DialogTitle id="alert-dialog-title">{data.id ? 'Edit' : 'Add'} User</DialogTitle>
          <DialogContent>
            <FormProvider {...methods}>
              <Grid container spacing={3}>
                <Grid item sm={6}>
                  <InputField name="name" label="Name" pattern={/^[a-zA-Z ]*$/i} />
                </Grid>
                <Grid item sm={6}>
                  <InputField name="email" label="Email" />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item sm={6}>
                  <SelectField
                    name="role_id"
                    label="Role"
                    options={roleList.map((data: any) => ({ value: data.id, label: data.name }))}
                  />
                </Grid>
                <Grid item sm={6}>
                  <AutocompleteField
                    name="company_id"
                    label="Company"
                    value={selectedCompany}
                    onChange={(val: any) => setSelectedCompany(val)}
                    options={companyList.map((data: any) => ({ value: data.id, label: data.name }))}
                  />
                  {/* <MultipleSelectField
                    name="company_id"
                    label="Company"
                    options={companyList.map((data: any) => ({ value: data.id, label: data.name }))}
                  /> */}
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item sm={6}>
                  <InputPasswordField
                    name="password"
                    label="Password"
                    autocomplete="new-password"
                  />
                </Grid>
                <Grid item sm={6}>
                  <InputPasswordField
                    name="confirm_password"
                    label="Confirm Password"
                    autocomplete="new-password"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item sm={3}>
                  <SwitchField name="status" label="Status" />
                </Grid>
              </Grid>
            </FormProvider>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button onClick={handleClose} variant="outlined" type="button">
              Cancel
            </Button>
            <LoadingButton
              loading={loading}
              loadingPosition="start"
              startIcon={<Save />}
              type="submit"
              variant="contained">
              Submit
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default FormDialog;
