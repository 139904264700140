import { Add } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import ModalDelete from '../../components/modal/ModalDelete';
import DataTable from '../../components/table/DataTable';
import provider from '../../redux/actions/provider';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import ChannelFormDialog from './ChannelFormDialog';
import { providerColumns } from './models/columns';
import ProviderChannel from './ProviderChannel';
import ProviderFormDialog from './ProviderFormDialog';

const ProviderIndex = ({ history, role }: any) => {
  const [dataSelected, setDataSelected] = useState();
  const [openProviderForm, setOpenProviderForm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [openChannelForm, setOpenChannelForm] = useState(false);
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.provider);

  useEffect(() => {
    dispatch(provider.fetchList(data.params));
    return () => dispatch(provider.resetParams());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAdd = () => {
    setDataSelected(undefined);
    setOpenProviderForm(true);
  };
  const handleEdit = (data: any) => {
    setDataSelected(data);
    setOpenProviderForm(true);
  };
  const handleDelete = (data: any) => {
    setDataSelected(data);
    setOpenDelete(true);
  };
  const onCloseDelete = (data: any) => {
    setDataSelected(undefined);
    setOpenDelete(false);
  };
  const handleDetail = (data: any) => {
    setDataSelected(data);
  };

  return (
    <Box>
      <Box component="h1" sx={{ mt: 0, fontSize: [22, 28, 32] }}>
        Provider Channel
      </Box>
      {/* <Grid container sx={{ mb: 1 }}>
        {role.create && (
          <Button variant="contained" startIcon={<Add />} onClick={handleAdd}>
            Add
          </Button>
        )}
      </Grid> */}
      <DataTable
        columns={providerColumns(role, handleDetail, handleEdit, handleDelete)}
        data={data}
        height="45vh"
        fetchList={provider.fetchList}
      />
      <ProviderFormDialog
        open={openProviderForm}
        handleClose={() => setOpenProviderForm(false)}
        data={dataSelected}
      />
      <ChannelFormDialog open={openChannelForm} handleClose={() => setOpenChannelForm(false)} />
      {dataSelected && <ProviderChannel provider={dataSelected} role={role} />}
      <ModalDelete
        open={openDelete}
        dataSelected={dataSelected}
        handleClose={onCloseDelete}
        deleteData={provider.deleteData}
        fetchList={provider.fetchList}
        data={data}
      />
    </Box>
  );
};

export default ProviderIndex;
