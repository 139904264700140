import { yupResolver } from '@hookform/resolvers/yup';
import { array, bool, number, object, string } from 'yup';

export interface IProviderForm {
  name: string;
  code: string;
  status: boolean;
  secret_templates: Array<any>;
}

export const providerDefaultValues = {
  name: '',
  code: '',
  status: true,
  secret_templates: [],
};

const secretTemplates = object({
  name: string().required('Required field'),
  type: string().required('Required field'),
});

export const providerResolver = yupResolver(
  object().shape({
    name: string().required('Required field'),
    code: string().required('Required field').min(4, 'Minimum 4 characters'),
    status: bool(),
    secret_templates: array().of(secretTemplates),
  }),
);

export interface IChannelForm {
  payment_provider_id: number;
  provider_name: string;
  provider_channel_code: string;
  payment_channel_id: string;
  code: string;
  max_transaction: string;
  sla: string;
  capability: number;
}

export const channelDefaultValues = {
  payment_provider_id: 0,
  provider_name: '',
  provider_channel_code: '',
  payment_channel_id: '',
  code: '',
  max_transaction: '',
  sla: '',
  capability: 1,
};

export const channelResolver = yupResolver(
  object({
    code: string().required('Required field').min(3, 'Minimum 3 characters'),
    provider_channel_code: string().required('Required field').min(3, 'Minimum 3 characters'),
    payment_channel_id: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('Required field'),
    capability: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('Required field'),
    max_transaction: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('Required field')
      .min(1, 'Must be greater than 0'),
    sla: number()
      .transform((value) => (isNaN(value) ? undefined : value))
      .required('Required field')
      .min(1, 'Must be greater than 0'),
  }),
);
