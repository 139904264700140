import { Add, Search } from "@mui/icons-material";
import { Button, Grid, OutlinedInput } from "@mui/material";
import { Box } from "@mui/system";
import { debounce } from "lodash";
import { useEffect, useState } from "react";
import ModalDelete from "../../components/modal/ModalDelete";
import DataTable from "../../components/table/DataTable";
import channel from "../../redux/actions/channel";
import { useAppDispatch, useAppSelector } from "../../redux/store";
import FormDialog from "./FormDialog";
import { columns } from "./models/columns";

const ChannelIndex = ({ history, role }: any) => {
    const [openForm, setOpenForm] = useState(false)
    const [openDelete, setOpenDelete] = useState(false)
    const [dataSelected, setDataSelected] = useState({})
    const dispatch = useAppDispatch()
    const data = useAppSelector(state => state.channel)

    useEffect(() => {
        dispatch(channel.fetchList(data.params))
        return () => dispatch(channel.resetParams())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAdd = () => {
        setDataSelected({})
        setOpenForm(true)
    }
    const handleEdit = (data: any) => {
        setDataSelected(data)
        setOpenForm(true)
    }
    const handleDelete = (data: any) => {
        setDataSelected(data)
        setOpenDelete(true)
    }
    const handleSearch = debounce((e) => {
        dispatch(channel.fetchList({ ...data.params, page: 1, key: null, value: e.target.value }));
    }, 500);

    return (
        <Box>
            <Box component="h1" sx={{ mt: 0, fontSize: [22, 28, 32] }}>Channel</Box>
            <Grid container>
                <OutlinedInput sx={{ mb: 1, mr: 1 }} onChange={handleSearch} placeholder="Search"
                    endAdornment={<Search color="disabled" />} size="small" />
                {role.create &&
                    <Button sx={{ mb: 1 }} variant="contained" startIcon={<Add />} onClick={handleAdd}>Add</Button>}
            </Grid>
            <DataTable
                columns={columns(role, handleEdit, handleDelete, history)}
                data={data}
                height="60vh"
                fetchList={channel.fetchList}
            />
            <FormDialog open={openForm} handleClose={() => setOpenForm(false)} data={dataSelected} />
            <ModalDelete
                open={openDelete}
                dataSelected={dataSelected}
                handleClose={() => setOpenDelete(false)}
                deleteData={channel.deleteData}
                fetchList={channel.fetchList}
                data={data}
                forbiddenError
                messageForbiddenError="Data sedang digunakan pada data lain. Silakan cek pada menu Partner, Provider Channel dan Company Provider."
            />
        </Box>
    )
}

export default ChannelIndex;
