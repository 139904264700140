import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { object, string } from 'yup';
import { InputField } from '../../components/fields/RenderField';
import { InputAdornment } from '@mui/material';
import { ContentCopy } from '@mui/icons-material';

interface IFormInputs {
  password: string;
}

const schema = object({
  password: string().required('Required field'),
});
const PasswordDialog = ({ open, handleClose, data }: any) => {
  const methods = useForm<IFormInputs>({
    resolver: yupResolver(schema),
  });
  const onSubmit = (value: any, e: any) => {
    console.log(value);
  };
  const onClose = () => {
    handleClose();
    methods.reset();
  };
  const onCopy = () => {
    navigator.clipboard.writeText(data?.provider_secrets);
  };

  return (
    <div>
      <Dialog
        open={open}
        maxWidth="xs"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          {/* <DialogTitle id="alert-dialog-title">
            Update Password
          </DialogTitle> */}
          <DialogContent>
            <FormProvider {...methods}>
              <InputField name="password" label="Password" />
              <InputField
                name="new_password"
                label=""
                defaultValue={data?.provider_secrets}
                disabled
                endAdornment={
                  <InputAdornment position="end">
                    <ContentCopy fontSize="small" sx={{ cursor: 'pointer' }} onClick={onCopy} />
                  </InputAdornment>
                }
              />
            </FormProvider>
          </DialogContent>
          <DialogActions sx={{ px: 3, pb: 3 }}>
            <Button onClick={onClose} variant="outlined" type="button">
              Cancel
            </Button>
            <Button type="submit" variant="contained">
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};

export default PasswordDialog;
