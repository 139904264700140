import * as React from 'react';
import { FormHelperText, Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FormProvider, useForm, useFieldArray } from 'react-hook-form';
import { InputField, SelectField, SwitchField } from '../../components/fields/RenderField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Save } from '@mui/icons-material';
import { IProductNewForm, resolver } from './models/formNew';
import { Box } from '@mui/system';
import { Grid, IconButton, InputLabel } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CloseIcon from '@mui/icons-material/Close';
import FormProviderChannel from './FormProviderChannel';
import { useFormViewModel } from './models/useFormViewModel';

const FormDialogNew = ({ open, handleClose, data }: any) => {
    const methods = useForm<IProductNewForm>({ resolver });
    const { control, watch, formState: { errors }, setValue } = methods;
    const companyId = watch('company_id');
    const { fields, append, remove } = useFieldArray({ control, name: "provider" });
    const {
        companyList,
        loading,
        onSubmit,
        providerList,
        handleChangeProvider,
        selectedProvider,
        validateFieldArray,
        resetSelectedProvider,
        handleChangeCompany,
        handleDeleteProvider,
    } = useFormViewModel({
        data,
        handleClose,
        open,
        methods,
        fields,
        companyId,
    });

    return (
        <div>
            <Dialog
                open={open}
                maxWidth="xl"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <DialogTitle id="alert-dialog-title">{data.id ? 'Edit' : 'Add'} Product</DialogTitle>
                    <DialogContent>
                        <FormProvider {...methods}>
                            <Grid container spacing={2}>
                                <Grid item sm={5}>
                                    <SelectField
                                        name="company_id"
                                        label="Company"
                                        options={companyList.map((data: any) => ({ value: data.id, label: data.name }))}
                                        customOnChange={(_: any) => handleChangeCompany()}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item sm={5}>
                                    <InputField name="product_name" label="Product Name" maxLength={100} pattern={/^[\w. ]*$/i} />
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item sm={5}>
                                    <InputField name="code" label="Product Code" maxLength={6} pattern={/^[\w ]*$/i} />
                                </Grid>
                            </Grid>

                            <Box sx={{ mb: 2 }}>
                                <IconButton
                                    color="primary"
                                    component="label"
                                    style={{ padding: 0 }}
                                    onClick={() => append({ provider_id: '', provider_channel: [] })}
                                >
                                    <AddCircleIcon />
                                    <InputLabel style={{ marginLeft: 8 }}>Add Provider</InputLabel>
                                </IconButton>
                            </Box>
                            {fields.map((item: any, index: number) => (
                                <Box key={item.id} sx={{ mb: 2, border: 1, borderColor: 'grey.400', borderRadius: 1, padding: 1 }}>
                                    <Grid container spacing={2}>
                                        <Grid item sm={4}>
                                            <SelectField
                                                name={`provider[${index}].provider_id`}
                                                label="Provider"
                                                disabled={!!item.disabled}
                                                options={providerList}
                                                customOnChange={(e: any) => handleChangeProvider(e.target.value, index)}
                                                isFieldArray
                                                isError={!!validateFieldArray(errors, index)}
                                                customError={
                                                    <FormHelperText sx={{ color: '#d32f2f' }}>
                                                        {validateFieldArray(errors, index)}
                                                    </FormHelperText>
                                                }
                                            />
                                        </Grid>
                                        <Grid item sm={8} textAlign="end">
                                            <IconButton
                                                color="error"
                                                component="label"
                                                style={{ padding: 0 }}
                                                onClick={() => { remove(index); handleDeleteProvider(index) }}
                                            >
                                                <CloseIcon />
                                            </IconButton>
                                        </Grid>
                                    </Grid>

                                    <FormProviderChannel
                                        control={control}
                                        nestedIndex={index}
                                        providerId={selectedProvider[index]}
                                        companyId={companyId}
                                        errors={errors}
                                        id={data.id}
                                        setValue={setValue}
                                    />
                                </Box>
                            ))}

                            <SwitchField name="status" label="Aktif" />
                        </FormProvider>
                    </DialogContent>
                    <DialogActions sx={{ px: 3, pb: 3 }}>
                        <Button onClick={() => {handleClose(); resetSelectedProvider();}} variant="outlined" type="button">
                            Cancel
                        </Button>
                        <LoadingButton
                            loading={loading}
                            loadingPosition="start"
                            startIcon={<Save />}
                            type="submit"
                            variant="contained">
                            Submit
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
};

export default FormDialogNew;
