import httpService from "../../../adapters/httpService";
import {URL_API} from "../../../configs";
import baseActions from "../baseActions";

const path = 'company';

export const actionType = (action: string) => {
    return {
        request: `${action}_${path}_request`.toUpperCase(),
        success: `${action}_${path}_success`.toUpperCase(),
        error: `${action}_${path}_error`.toUpperCase()
    }
}
const index = {
    ...baseActions(path, URL_API, actionType),
    fetchAll: (params?: any) => {
        const http = httpService();
        return (dispatch: any) => {
            dispatch({type: actionType('fetchAll').request});
            return http.get(`${URL_API}/companies`, {params: {status: true, ...params}}).then(
                response => {
                    dispatch({type: actionType('fetchAll').success, response: response.data});
                    return response.data;
                },
                error => {
                    dispatch({type: actionType('fetchAll').error});
                    throw error.response;
                }
            );
        };
    },
    fetchCompanyChannels: (companyId: number) => {
        const http = httpService();
        return (dispatch: any) => {
            dispatch({type: actionType('fetchCompanyChannels').request});
            return http.get(`${URL_API}/company-channel/company/${companyId}`, {params: {status: true}}).then(
                response => {
                    dispatch({type: actionType('fetchCompanyChannels').success, response: response.data});
                    return response.data;
                },
                error => {
                    dispatch({type: actionType('fetchCompanyChannels').error});
                    throw error.response;
                }
            );
        };
    },
    fetchCompanyProviders: (companyId: number, channelId: number) => {
        const http = httpService();
        return (dispatch: any) => {
            dispatch({type: actionType('fetchCompanyProviders').request});
            return http.get(`${URL_API}/company-channel/company/${companyId}/channel/${channelId}`, {params: {status: true}})
                .then(
                    response => {
                        dispatch({type: actionType('fetchCompanyProviders').success, response: response.data});
                        return response.data;
                    },
                    error => {
                        dispatch({type: actionType('fetchCompanyProviders').error});
                        throw error.response;
                    }
                );
        };
    },
    generateApiKey: () => {
        const http = httpService();
        return (dispatch: any) => {
            dispatch({type: actionType('generateApiKey').request});
            return http.get(`${URL_API}/companyGenerateApiKey`)
                .then(
                    response => {
                        dispatch({type: actionType('generateApiKey').success, response: response.data});
                        return response.data;
                    },
                    error => {
                        dispatch({type: actionType('generateApiKey').error});
                        throw error.response;
                    }
                );
        };
    },
}
export default index;
