import {URL_API} from "../../../configs";
import baseActions from "../baseActions";

const path = 'provider/company/mapping';

export const actionType = (action: string) => {
    return {
        request: `${action}_${path}_request`.toUpperCase(),
        success: `${action}_${path}_success`.toUpperCase(),
        error: `${action}_${path}_error`.toUpperCase()
    }
}
const index = {
    ...baseActions(path, URL_API, actionType),
}
export default index;
