import httpService from "../../adapters/httpService";

const baseActions = (path: any, baseUrl: string, actionType: any) => {
    const http = httpService();
    return {
        fetchList: (params?: any) => {
            return (dispatch: any) => {
                dispatch({type: actionType('fetchList').request});
                return http.get(`${baseUrl}/${path}`, {params}).then(
                    response => {
                        dispatch({type: actionType('fetchList').success, response: response.data, params});
                        return response.data;
                    },
                    error => {
                        dispatch({type: actionType('fetchList').error});
                        throw error.response;
                    }
                );
            };
        },

        fetchAll: (params?: any) => {
            return (dispatch: any) => {
                dispatch({type: actionType('fetchAll').request});
                return http.get(`${baseUrl}/${path}/all`, {params: {...params, status: true}}).then(
                    response => {
                        dispatch({type: actionType('fetchAll').success, response: response.data});
                        return response.data;
                    },
                    error => {
                        dispatch({type: actionType('fetchAll').error});
                        throw error.response;
                    }
                );
            };
        },

        fetchDetail: (id: number, params?: any) => {
            return (dispatch: any) => {
                dispatch({type: actionType('fetchDetail').request});
                return http.get(`${baseUrl}/${path}/id/${id}`, {params}).then(
                    response => {
                        dispatch({type: actionType('fetchDetail').success, response: response.data});
                        return response.data;
                    },
                    error => {
                        dispatch({type: actionType('fetchDetail').error});
                        throw error.response;
                    }
                );
            };
        },

        createData: (data: any) => {
            return (dispatch: any) => {
                dispatch({type: actionType('create').request});
                return http.post(`${baseUrl}/${path}`, data).then(
                    response => {
                        dispatch({type: actionType('create').success});
                        return response.data;
                    },
                    error => {
                        dispatch({type: actionType('create').error});
                        throw error.response;
                    }
                );
            };
        },

        updateData: (id: number, data: any) => {
            return (dispatch: any) => {
                dispatch({type: actionType('update').request});
                return http.put(`${baseUrl}/${path}/${id}`, data).then(
                    response => {
                        dispatch({type: actionType('update').success});
                        return response.data;
                    },
                    error => {
                        dispatch({type: actionType('update').error});
                        throw error.response;
                    }
                );
            };
        },

        deleteData: (id: number) => {
            return (dispatch: any) => {
                dispatch({type: actionType('delete').request});
                return http.delete(`${baseUrl}/${path}/${id}`).then(
                    response => {
                        dispatch({type: actionType('delete').success});
                        return response.data;
                    },
                    error => {
                        dispatch({type: actionType('delete').error});
                        throw error.response;
                    }
                );
            };
        },

        resetParams: () => (dispatch: any) => {
            dispatch({type: actionType('resetParams').success});
        },

    }
}
export default baseActions;
