import { Add } from '@mui/icons-material';
import { Button, Grid } from '@mui/material';
import { Box } from '@mui/system';
import { useEffect, useState } from 'react';
import ModalDelete from '../../components/modal/ModalDelete';
import DataTable from '../../components/table/DataTable';
import providerChannel from '../../redux/actions/provider-channel';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import ChannelFormDialog from './ChannelFormDialog';
import { channelColumns } from './models/columns';

const ProviderChannel = ({ provider, role }: any) => {
  const [dataSelected, setDataSelected] = useState();
  const [openChannelForm, setOpenChannelForm] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const dispatch = useAppDispatch();
  const data = useAppSelector((state) => state.providerChannel);

  useEffect(() => {
    if (provider) {
      dispatch(providerChannel.fetchList({ ...data.params, id: provider.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [provider]);

  const handleAdd = () => {
    setDataSelected(undefined);
    setOpenChannelForm(true);
  };
  const handleEdit = (data: any) => {
    setDataSelected(data);
    setOpenChannelForm(true);
  };
  const handleDelete = (data: any) => {
    setDataSelected(data);
    setOpenDelete(true);
  };
  return (
    <Box>
      <Box component="h2" sx={{ textTransform: 'capitalize' }}>
        {provider?.name} Payment Channel
      </Box>
      <Grid container sx={{ mb: 1 }}>
        {role.create && (
          <Button variant="contained" startIcon={<Add />} onClick={handleAdd}>
            Add Channel
          </Button>
        )}
      </Grid>
      <DataTable
        columns={channelColumns(role, handleEdit, handleDelete)}
        data={data}
        height="45vh"
        fetchList={providerChannel.fetchList}
      />
      <ChannelFormDialog
        open={openChannelForm}
        handleClose={() => setOpenChannelForm(false)}
        data={dataSelected}
        provider={provider}
      />
      <ModalDelete
        open={openDelete}
        dataSelected={dataSelected}
        handleClose={() => setOpenDelete(false)}
        deleteData={providerChannel.deleteData}
        fetchList={providerChannel.fetchList}
        data={data}
      />
    </Box>
  );
};

export default ProviderChannel;
