import httpService from "../../../adapters/httpService";
import {URL_API} from "../../../configs";
import baseActions from "../baseActions";

const path = 'partner';

export const actionType = (action: string) => {
    return {
        request: `${action}_${path}_request`.toUpperCase(),
        success: `${action}_${path}_success`.toUpperCase(),
        error: `${action}_${path}_error`.toUpperCase()
    }
}
const index = {
    ...baseActions(path, URL_API, actionType),
    getPartnerCompany: (id: number) => {
        const http = httpService();
        return (dispatch: any) => {
            dispatch({type: actionType('getPartnerCompany').request});
            return http.get(`${URL_API}/partner/company/${id}`, {params: {status: true}}).then(
                response => {
                    dispatch({type: actionType('getPartnerCompany').success, response: response.data});
                    return response.data;
                },
                error => {
                    dispatch({type: actionType('getPartnerCompany').error});
                    throw error.response;
                }
            );
        };
    },
    fetchListData: (params?: any) => {
        const http = httpService();
        return http.get(`${URL_API}/${path}`, {params}).then(
            response => {
                return response.data;
            },
            error => {
                throw error.response;
            }
        );
    },
}
export default index;
