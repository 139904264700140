import { Grid, FormHelperText } from "@mui/material";
import { useFieldArray } from "react-hook-form";
import IconButton from "@mui/material/IconButton";
import { InputField, SelectField, SwitchField } from "../../components/fields/RenderField";
import DeleteIcon from '@mui/icons-material/Delete';
import { Fragment, useEffect } from "react";
import { Box } from "@mui/system";
import InputLabel from "@mui/material/InputLabel";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useFormChildViewModel } from "./models/useFormChildViewModel";

const FormProviderChannel = (props: any) => {
  const { nestedIndex, control, providerId, companyId, errors, id, setValue } = props;
  const { fields, remove, append } = useFieldArray({
    control,
    name: `provider[${nestedIndex}].provider_channel`
  });
  const { providerChannelList, handleChangeProviderChannel, validateFieldArray } = useFormChildViewModel({
    providerId,
    companyId,
    fields,
    id,
    setValue,
    nestedIndex,
  });

  return (
    <Fragment key={`channel-${nestedIndex}`}>
      {fields.map((item: any, index: number) => (
        <Grid key={item.id} container spacing={2}>
          <Grid item sm={4}>
            <InputField
              name={`provider[${nestedIndex}].provider_channel[${index}].id`}
              label="id"
              type="number"
              disabled
              style={{ display: 'none' }}
            />
            <SelectField
              name={`provider[${nestedIndex}].provider_channel[${index}].provider_channel_id`}
              label="Provider Channel"
              disabled={!!item.disabled}
              options={providerChannelList}
              customOnChange={(e: any) => handleChangeProviderChannel(e.target.value, index)}
              isFieldArray
              isError={!!validateFieldArray(errors, nestedIndex, index, 'provider_channel_id')}
              customError={
                <FormHelperText sx={{ color: '#d32f2f' }}>
                  {validateFieldArray(errors, nestedIndex, index, 'provider_channel_id')}
                </FormHelperText>
              }
            />
          </Grid>
          <Grid item sm={1}>
            <InputField
              name={`provider[${nestedIndex}].provider_channel[${index}].cash_in_fix`}
              label="Cash In (Rp)"
              type="number"
              disabled
            />
          </Grid>
          <Grid item sm={1}>
            <InputField
              name={`provider[${nestedIndex}].provider_channel[${index}].cash_in_percentage`}
              label="Cash In (%)"
              type="number"
              disabled
            />
          </Grid>
          <Grid item sm={2}>
            <InputField
              name={`provider[${nestedIndex}].provider_channel[${index}].fee_fix_value`}
              label="Transaction Fee (Fix)"
              type="number"
              isFieldArray
              customError={validateFieldArray(errors, nestedIndex, index, 'fee_fix_value')}
            />
          </Grid>
          <Grid item sm={2}>
            <InputField
              name={`provider[${nestedIndex}].provider_channel[${index}].fee_percentage`}
              label="Transaction Fee (%)"
              type="number"
              max={100}
              isFieldArray
              customError={validateFieldArray(errors, nestedIndex, index, 'fee_percentage')}
            />
          </Grid>
          <Grid item sm={1} textAlign="center" alignItems="center">
            <br />
            <IconButton
              color="error"
              component="label"
              style={{ padding: 0 }}
              onClick={() => { remove(index); handleChangeProviderChannel('', index) }}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
          <Grid item sm={1} textAlign="end">
            <br />
            <SwitchField name={`provider[${nestedIndex}].provider_channel[${index}].status`} label="Aktif" />
          </Grid>
        </Grid>
      ))}

      <Box sx={{ mb: 2 }}>
        <IconButton
          color="primary"
          component="label"
          style={{ padding: 0 }}
          onClick={() => append({ status: true })}
        >
          <AddCircleIcon />
          <InputLabel style={{ marginLeft: 8 }}>Add Provider Channel</InputLabel>
        </IconButton>
      </Box>
    </Fragment>
  )
}

export default FormProviderChannel;