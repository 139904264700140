import {actionType} from "../../actions/auth";

const initState = {
    isLoading: false,
    current: null
};

export default function reducer(state = initState, action: any) {
    const {type, payload} = action;
    switch (type) {
        case actionType('login').request:
            return {
                ...state,
                isLoading: true
            };
        case actionType('login').success:
            return {
                ...state,
                current: payload,
                isLoading: false
            };
        case actionType('login').error:
            return {
                ...state,
                isLoading: false
            };

        default:
            return state;
    }
}
