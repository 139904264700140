import baseInitState from '../baseInitState';
import baseReducer from '../baseReducer';
import { actionType } from '../../actions/channel';

const initState = {
  ...baseInitState,
  channelTypes: [],
};

export default function reducer(state = initState, action: any) {
  switch (action.type) {
    case actionType('getChannelType').success:
      return {
        ...state,
        channelTypes: action.response.data || [],
      };

    default:
      return baseReducer(state, action, actionType);
  }
}
