import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { FormProvider, useForm } from 'react-hook-form';
import { InputField, SelectField, SwitchVerticalField } from '../../components/fields/RenderField';
import { alertError, alertSuccess } from '../../utils/alert';
import LoadingButton from '@mui/lab/LoadingButton';
import { Save } from '@mui/icons-material';
import { useAppDispatch, useAppSelector } from '../../redux/store';
import company from '../../redux/actions/company';
import { defaultValues, ICompanyForm, resolver } from './models/form';
import { Grid, InputLabel, Snackbar } from '@mui/material';
import CachedIcon from '@mui/icons-material/Cached';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const FormDialog = ({ open, handleClose, data }: any) => {
    const methods = useForm<ICompanyForm>({ resolver });
    const [snackBarOpen, setSnackBarOpen] = React.useState<boolean>(false);

    const loading = useAppSelector(state => state.company.isLoadingSubmit)
    const params = useAppSelector(state => state.company.params)
    const companyList = useAppSelector(state => state.company.dataAll)
    const dispatch = useAppDispatch()
    const child = methods.watch('child')

    React.useEffect(() => {
        dispatch(company.fetchAll())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    React.useEffect(() => {
        if (data?.id && open === true) {
            let isParent = data?.parent_company_id === null || data?.parent_company_id === data?.id
            methods.reset({
                ...data,
                child: !isParent,
                parent_company_id: isParent ? '' : String(data?.parent_company_id)
            })
        } else {
            methods.reset(defaultValues)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const parentCompanies = () => {
        let filtered = companyList.filter((item: any) => (item?.parent_company_id === null || item?.parent_company_id === item?.id) && item.id !== data?.id)
        return filtered.map((item: any) => ({ value: item.id, label: item.name }))
    }

    const onSubmit = async (value: any) => {
        try {
            if (data?.id) {
                await dispatch(company.updateData(data?.id, {
                    ...value,
                    parent_company_id: child ? value.parent_company_id : data?.id
                }))
            } else {
                await dispatch(company.createData({
                    ...value,
                    parent_company_id: child ? value.parent_company_id : null
                }))
            }
            alertSuccess(`Company berhasil di ${data?.id ? 'update' : 'tambahkan'}`).then(
                () => {
                    handleClose()
                    dispatch(company.fetchList(params))
                    dispatch(company.fetchAll())
                }
            );
        } catch (error: any) {
            const message = error.status === 403 ? 'Data sedang digunakan pada data lain. Silakan cek pada menu User, Product, Partner, Company Provider, dan field Subsidiary.' : error?.data?.message;
            alertError(message || 'Maaf, terjadi kesalahan')
        }
    }

    const handleGenerateKey = async () => {
        try {
            const { data: { api_key } } = await dispatch(company.generateApiKey());

            methods.setValue('secrets', api_key);

            methods.clearErrors('secrets');
        } catch (error: any) {
            alertError(error?.data?.message || 'Maaf, terjadi kesalahan')
        }

    }

    const handleCopySecret = async () => {
        const fieldSecret = document.getElementsByName('secrets')[0] as HTMLInputElement;

        await navigator.clipboard.writeText(fieldSecret.value);

        setSnackBarOpen(true);
    }

    return (
        <div>
            <Dialog
                open={open}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                sx={{
                    "& .MuiDialog-container": {
                        "& .MuiPaper-root": {
                            width: "100%",
                            maxWidth: "800px",  // Set your width here
                        },
                    },
                }}
            >
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <DialogTitle id="alert-dialog-title">
                        {data?.id ? 'Edit' : 'Add'} Company
                    </DialogTitle>
                    <DialogContent>
                        <FormProvider {...methods}>
                            <InputField name="name" label="Name" maxLength={100} pattern={/^[a-zA-Z. ]*$/i} />
                            <Grid container spacing={2}>
                                <Grid item sm={6}>
                                    <InputField name="code" label="Code" maxLength={6} pattern={/^[a-zA-Z]*$/i} />
                                </Grid>
                                <Grid item sm={6}>
                                    <InputField name="alias" label="Alias" maxLength={6} pattern={/^[a-zA-Z]*$/i} />
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item sm={12}>
                                    <InputLabel>API Key</InputLabel>
                                </Grid>
                                <Grid container sm={12} spacing={2}>
                                    <Grid item sm={10}>
                                        <InputField name="secrets" maxLength={6} disabled />
                                    </Grid>
                                    <Grid item sm={1}>
                                        <Button onClick={handleCopySecret} variant="outlined" type="button" style={{ minWidth: 24 }}>
                                            <ContentCopyIcon />
                                        </Button>
                                    </Grid>
                                    <Grid item sm={1} style={{ textAlign: 'end' }}>
                                        <Button onClick={handleGenerateKey} variant="outlined" type="button" style={{ minWidth: 24 }}>
                                            <CachedIcon />
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item sm={6}>
                                    <SwitchVerticalField name="child" label="Subsidiary" />
                                </Grid>
                                <Grid item sm={6}>
                                    <SwitchVerticalField name="status" label="Aktif" />
                                </Grid>
                            </Grid>
                            {child && <Grid container spacing={2}>
                                <Grid item sm={12}>
                                    <SelectField
                                        name="parent_company_id"
                                        label="Parent Company"
                                        options={parentCompanies()}
                                    />
                                </Grid>
                            </Grid>}
                        </FormProvider>
                    </DialogContent>
                    <DialogActions sx={{ px: 3, pb: 3 }}>
                        <Button onClick={handleClose} variant="outlined" type="button">Cancel</Button>
                        <LoadingButton loading={loading} loadingPosition="start" startIcon={<Save />} type="submit"
                            variant="contained">
                            Submit
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>

            <Snackbar
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={snackBarOpen}
                onClose={() => setSnackBarOpen(false)}
                message="Secret key Copied"
                autoHideDuration={3000}
            />
        </div>
    );
}

export default FormDialog
