import httpService from "../../../adapters/httpService";
import { URL_API } from "../../../configs";
import baseActions from "../baseActions";

const path = 'company-product';

export const actionType = (action: string) => {
    return {
        request: `${action}_${path}_request`.toUpperCase(),
        success: `${action}_${path}_success`.toUpperCase(),
        error: `${action}_${path}_error`.toUpperCase()
    }
}
const index = {
    ...baseActions(path, URL_API, actionType),
    createDataNew: (data: any) => {
        const http = httpService();
        return http.post(`${URL_API}/${path}`, data).then(
            response => response.data,
            error => {
                throw error.response;
            }
        );
    },
    updateDataNew: (id: string, data: any) => {
        const http = httpService();
        return http.put(`${URL_API}/${path}/${id}`, data).then(
            response => response.data,
            error => {
                throw error.response;
            }
        );
    },
    getDetailChannel: (id: string) => {
        const http = httpService();
        return http.get(`${URL_API}/${path}/${id}/payment-channels`).then(
            response => response.data,
            error => {
                throw error.response;
            }
        );
    },
    createBulkChannel: (id: string, data: any) => {
        const http = httpService();
        return http.post(`${URL_API}/${path}/${id}/payment-channels/bulk`, data).then(
            response => response.data,
            error => {
                throw error.response;
            }
        );
    },
    updateBulkChannel: (id: string, data: any) => {
        const http = httpService();
        return http.put(`${URL_API}/${path}/${id}/payment-channels/bulk`, data).then(
            response => response.data,
            error => {
                throw error.response;
            }
        );
    },
    deleteBulkChannel: (id: string, data: any) => {
        const http = httpService();
        return http.delete(`${URL_API}/${path}/${id}/payment-channels/bulk`, data).then(
            response => response.data,
            error => {
                throw error.response;
            }
        );
    },
}

export default index;
