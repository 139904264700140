import baseInitState from "../baseInitState";
import baseReducer from "../baseReducer";
import {actionType} from "../../actions/product";

const initState = {
    ...baseInitState,
};

export default function reducer(state = initState, action: any) {
    switch (action.type) {
        case actionType('action').success: //example action
            return {
                ...state,
            };

        default:
            return baseReducer(state, action, actionType);
    }
}
