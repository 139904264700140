import baseInitState from './baseInitState';

const baseReducer = (state: any, action: any, actionType: any) => {
  switch (action.type) {
    case actionType('fetchList').request:
      return {
        ...state,
        isLoading: true,
      };
    case actionType('fetchList').success:
      return {
        ...state,
        isLoading: false,
        dataList: action.response.data || [],
        pagination: {
          currentPage: action.response?.pagination?.page || 1,
          lastPage: action.response?.pagination?.total_page || 1,
          count: action.response?.pagination?.total_data || 0,
          recordPerPage: action.response?.pagination?.limit || 10,
        },
        params: action.params,
      };
    case actionType('fetchList').error:
      return {
        ...state,
        isLoading: false,
      };

    case actionType('fetchAll').request:
      return {
        ...state,
        isLoading: true,
      };
    case actionType('fetchAll').success:
      return {
        ...state,
        isLoading: false,
        dataAll: action.response.data || [],
      };
    case actionType('fetchAll').error:
      return {
        ...state,
        isLoading: false,
      };

    case actionType('fetchDetail').request:
      return {
        ...state,
        isLoadingDetail: true,
      };
    case actionType('fetchDetail').success:
      return {
        ...state,
        isLoadingDetail: false,
        dataDetail: action.response.data,
      };
    case actionType('fetchDetail').error:
      return {
        ...state,
        isLoadingDetail: false,
        dataDetail: null,
      };

    case actionType('create').request:
      return {
        ...state,
        isLoadingSubmit: true,
      };
    case actionType('create').success:
      return {
        ...state,
        isLoadingSubmit: false,
      };
    case actionType('create').error:
      return {
        ...state,
        isLoadingSubmit: false,
      };

    case actionType('update').request:
      return {
        ...state,
        isLoadingSubmit: true,
      };
    case actionType('update').success:
      return {
        ...state,
        isLoadingSubmit: false,
      };
    case actionType('update').error:
      return {
        ...state,
        isLoadingSubmit: false,
      };

    case actionType('delete').request:
      return {
        ...state,
        isLoadingDelete: true,
      };
    case actionType('delete').success:
      return {
        ...state,
        isLoadingDelete: false,
      };
    case actionType('delete').error:
      return {
        ...state,
        isLoadingDelete: false,
      };

    case actionType('resetParams').success:
      return {
        ...state,
        params: baseInitState.params,
      };
    default:
      return state;
  }
};
export default baseReducer;
