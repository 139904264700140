export const removeUnderline = (value: string, flag: string) => {
    if (typeof value !== 'string') {
        return flag || ''
    }
    return value.replace(/_/g, ' ')
}

export const formatSentence = (value: string, flag?: string) => {
    if (typeof value !== 'string' || value === '') {
        return flag || ''
    }
    let capitalize = value.charAt(0).toUpperCase() + value.slice(1)
    return capitalize.replace(/_/g, ' ')
}

export const formatCapitalize = (value: string, flag?: string, removeUnderline = true) => {
    if (typeof value !== 'string' || value === '') {
        return flag || ''
    }
    if (removeUnderline) {
        value = value.replace(/_/g, ' ')
    }
    let words = value.split(" ")
    for (let i = 0; i < words.length; i++) {
        words[i] = words[i][0].toUpperCase() + words[i].slice(1);
    }
    return words.join(" ")
}

export const formatMoney = (value: string | number) => {
    if (value === undefined || value === '') {
        return '0'
    }
    let valueString = value as string
    if (typeof value === 'number') {
        valueString = value.toString()
    }
    return valueString.replace(/(\d)(?=(\d{3})+\b)/g, '$1,')
}
