import { Box, Drawer, List } from '@mui/material';
import { Fragment } from 'react';
import MenuList from '../../routers/MenuList';
import DrawerCustom from './Drawer';
import SidebarItem from './SidebarItem';

export default function Sidebar(props: any) {
  const { drawerOpen, handleDrawerToggle, mobileView } = props;

  return (
    <Box component="nav">
      <DrawerCustom
        variant="permanent"
        open={drawerOpen}
        sx={{
          display: { xs: 'none', md: 'block' },
        }}>
        <List sx={{ pt: 9 }}>
          {MenuList.map((v, x) => {
            if (v.showMenu) {
              return <SidebarItem key={x} data={v} {...props} />;
            } else {
              return <Fragment key={x}></Fragment>;
            }
          })}
        </List>
      </DrawerCustom>
      <Drawer
        variant="temporary"
        open={drawerOpen && mobileView}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }}
        sx={{
          display: { xs: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 260 },
        }}>
        <List sx={{ pt: [7, 9] }}>
          {MenuList.map((v, x) => {
            if (v.showMenu) {
              return <SidebarItem key={x} data={v} {...props} />;
            } else {
              return <Fragment key={x}></Fragment>;
            }
          })}
        </List>
      </Drawer>
    </Box>
  );
}
