import * as React from 'react';
import { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { alertError, alertSuccess } from '../../utils/alert';
import LoadingButton from '@mui/lab/LoadingButton';
import partner from '../../redux/actions/partner';
import channel from '../../redux/actions/channel';
import cashin from '../../redux/actions/cash-in';
import { Save } from '@mui/icons-material';
import { ICashInExportForm, defaultCashInExportForm } from './models/form';
import { Box, FormHelperText, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateAdapter from '@mui/lab/AdapterMoment';
import DatePicker from '@mui/lab/DatePicker';
import moment from 'moment';
import Grid from '@mui/material/Grid';

interface Props {
    open: boolean;
    handleClose: () => void;
}

const options = [
    { value: '', label: 'All Status' },
    { value: 'reconciled', label: 'Reconciled' },
    { value: 'pending', label: 'Pending' },
    { value: 'paid', label: 'Paid' },
    { value: 'refund', label: 'Refund' },
    { value: 'failed', label: 'Failed' },
    { value: 'expired', label: 'Expired' },
]

const CashInExportDialog: React.FC<Props> = (props) => {
    const { open, handleClose } = props;
    const [paymentChannelList, setPaymentChannelList] = useState<Array<any>>([{ label: 'All Payment Channel', value: '' }]);
    const [partnerList, setPartnerList] = useState<Array<any>>([{ label: 'All Partner Name', value: '' }]);
    const [form, setForm] = useState<ICashInExportForm>({
        ...defaultCashInExportForm,
    })
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<{ start_date: boolean, end_date: boolean }>({ start_date: false, end_date: false })
    const [openStartDate, setOpenStartDate] = useState<boolean>(false);
    const [openEndDate, setOpenEndDate] = useState<boolean>(false);

    const handleChangeForm = (key: 'statuses' | 'payment_channel_id' | 'partner_id', event: SelectChangeEvent) => {
        const {
            target: { value },
        }: any = event;
        let newValue: any = [];

        if (key === 'statuses') {
            if (value.includes('')) {
                if (value.length === options.length - 1 && form.statuses.includes('')) {
                    const temp = [...value];
                    temp.splice(0, 1);
                    newValue = temp;
                } else newValue = options.map(val => val.value);
            }
            else if (form.statuses.includes('') && !value.includes('')) newValue = [];
            else if (!form.statuses.includes('') && !value.includes('') && value.length === options.length - 1) newValue = options.map(val => val.value);
            else newValue = value;
        } else if (key === 'partner_id') {
            if (value.includes('')) {
                if (value.length === partnerList.length - 1 && form.partner_id.includes('')) {
                    const temp = [...value];
                    temp.splice(0, 1);
                    newValue = temp;
                } else newValue = partnerList.map(val => val.value);
            }
            else if (form.partner_id.includes('') && !value.includes('')) newValue = [];
            else if (!form.partner_id.includes('') && !value.includes('') && value.length === partnerList.length - 1) newValue = partnerList.map(val => val.value);
            else newValue = value;
        } else if (key === 'payment_channel_id') {
            if (value.includes('')) {
                if (value.length === paymentChannelList.length - 1 && form.payment_channel_id.includes('')) {
                    const temp = [...value];
                    temp.splice(0, 1);
                    newValue = temp;
                } else newValue = paymentChannelList.map(val => val.value);
            }
            else if (form.payment_channel_id.includes('') && !value.includes('')) newValue = [];
            else if (!form.payment_channel_id.includes('') && !value.includes('') && value.length === paymentChannelList.length - 1) newValue = paymentChannelList.map(val => val.value);
            else newValue = value;
        }

        setForm(prev => ({ ...prev, [key]: newValue }));
    }

    const handleChangeDate = (key: string, value: string) => {
        setError(prev => ({ ...prev, [key]: false }))
        setForm(prev => ({ ...prev, [key]: value }))
    }

    const onSubmit = async () => {
        if (!(form.start_date) || !(form.end_date)) return setError({
            start_date: !(form.start_date),
            end_date: !(form.end_date),
        })

        setLoading(true);

        let payment_channel_id = undefined,
            partner_id = undefined,
            statuses = undefined;

        if (form.payment_channel_id.length > 0 && !form.payment_channel_id.includes('')) payment_channel_id = form.payment_channel_id.join();
        if (form.partner_id.length > 0 && !form.partner_id.includes('')) partner_id = form.partner_id.join();
        if (form.statuses.length > 0 && !form.statuses.includes('')) statuses = form.statuses.join();

        try {
            const payload = {
                start_date: moment(form.start_date).format('YYYY-MM-DD'),
                end_date: moment(form.end_date).format('YYYY-MM-DD'),
                payment_channel_id,
                partner_id,
                statuses,
            };

            const res = await cashin.export(payload);

            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `Cash In Report - ${moment().format('DDMMYYYY')}.xlsx`);
            document.body.appendChild(link);
            link.click();

            alertSuccess(`Export Data Berhasil`).then(() => {
                handleClose();
            });
        } catch (error: any) {
            const res: any = JSON.parse(String.fromCharCode.apply(null, new Uint8Array(error?.data) as any));
            alertError(res.message || 'Maaf, terjadi kesalahan');
        } finally {
            setLoading(false);
        }
    };

    const sortArray = (a: any, b: any) => {
        if (a.label < b.label) {
            return -1;
        }
        if (a.label > b.label) {
            return 1;
        }
        return 0;
    }

    const fetchData = async () => {
        try {
            const resChannel: any = await channel.fetchAllList();
            const dataChannel = resChannel.data.map((val: any) => ({ label: val.name, value: val.id }));
            const sortedDataChannel = dataChannel.sort(sortArray);
            setPaymentChannelList([{ label: 'All Payment Channel', value: '' }, ...sortedDataChannel]);
            const resPartner: any = await partner.fetchListData({ page: 1, limit: 100, order: 'name', sord: 'desc' });
            const dataPartner = resPartner.data.map((val: any) => ({ label: val.partner, value: val.id }));
            const sortedDataPartner = dataPartner.sort(sortArray);
            setPartnerList([{ label: 'All Partner Name', value: '' }, ...sortedDataPartner]);

            setForm(prev => ({
                ...prev,
                statuses: options.map(val => val.value),
                partner_id: ['', ...sortedDataPartner.map((val: any) => val.value)],
                payment_channel_id: ['', ...sortedDataChannel.map((val: any) => val.value)],
            }))
        } catch (e) {

        }
    }

    useEffect(() => {
        if (open) {
            setForm({ ...defaultCashInExportForm });
            setError({ start_date: false, end_date: false });
            fetchData();
        }
    }, [open])

    const renderSelect = (val: Array<any>, list: Array<any>) => {
        let result = [];

        const temp: any = val.includes('');
        if (temp) return list[0].label;

        for (let i = 0; i < val.length; i++) {
            const temp = list.find((x: any) => x.value === val[i]);

            result.push(temp.label);
        }

        return <span>{result.join(', ')}</span>;
    }

    return (
        <div>
            <Dialog
                open={open}
                maxWidth="xs"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    Export Data
                </DialogTitle>
                <DialogContent>
                    <Box sx={{ mb: 2 }}>
                        <InputLabel>Start Date</InputLabel>
                        <LocalizationProvider dateAdapter={DateAdapter}>
                            <DatePicker
                                open={openStartDate}
                                onOpen={() => setOpenStartDate(true)}
                                onClose={() => setOpenStartDate(false)}
                                value={form.start_date}
                                onChange={(value) => handleChangeDate('start_date', value)}
                                disableFuture
                                inputFormat="DD/MM/YYYY"
                                renderInput={(props: any) => (
                                    <React.Fragment>
                                        <TextField
                                            {...props}
                                            label=""
                                            size="small"
                                            style={{ width: '100%' }}
                                            onClick={(e) => setOpenStartDate(true)}
                                        />
                                    </React.Fragment>
                                )}
                            />
                        </LocalizationProvider>
                        {error.start_date && (
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                                Required field
                            </FormHelperText>
                        )}
                    </Box>

                    <Box sx={{ mb: 2 }}>
                        <InputLabel>End Date</InputLabel>
                        <LocalizationProvider dateAdapter={DateAdapter}>
                            <DatePicker
                                open={openEndDate}
                                onOpen={() => setOpenEndDate(true)}
                                onClose={() => setOpenEndDate(false)}
                                value={form.end_date}
                                onChange={(value) => handleChangeDate('end_date', value)}
                                disableFuture
                                minDate={form.start_date || undefined}
                                inputFormat="DD/MM/YYYY"
                                renderInput={(props: any) => (
                                    <React.Fragment>
                                        <TextField
                                            {...props}
                                            label=""
                                            size="small"
                                            style={{ width: '100%' }}
                                            onClick={(e) => setOpenEndDate(true)}
                                        />
                                    </React.Fragment>
                                )}
                            />
                        </LocalizationProvider>
                        {error.end_date && (
                            <FormHelperText sx={{ color: '#d32f2f' }}>
                                Required field
                            </FormHelperText>
                        )}
                    </Box>

                    <Box sx={{ mb: 2 }}>
                        <InputLabel>Cash In Status</InputLabel>
                        <Select
                            fullWidth
                            displayEmpty
                            size="small"
                            multiple
                            value={form.statuses}
                            onChange={(val: any) => handleChangeForm('statuses', val)}
                            renderValue={val => val.length > 0 ? renderSelect(val, options) : ''}
                        >
                            {options.map((item: any, index: number) => (
                                <MenuItem key={index} value={item.value}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            {item.label}
                                        </Grid>
                                        <Grid item xs={6} justifyContent="end" textAlign="end">
                                            {form.statuses.includes(item.value) && (
                                                <CheckIcon fontSize="small" />
                                            )}
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>

                    <Box sx={{ mb: 2 }}>
                        <InputLabel>Payment Channel</InputLabel>
                        <Select
                            fullWidth
                            displayEmpty
                            size="small"
                            multiple
                            value={form.payment_channel_id}
                            onChange={(val: any) => handleChangeForm('payment_channel_id', val)}
                            renderValue={val => val.length > 0 ? renderSelect(val, paymentChannelList) : ''}
                        >
                            {paymentChannelList.map((item: any, index: number) => (
                                <MenuItem key={index} value={item.value}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            {item.label}
                                        </Grid>
                                        <Grid item xs={6} justifyContent="end" textAlign="end">
                                            {form.payment_channel_id.includes(item.value) && (
                                                <CheckIcon fontSize="small" />
                                            )}
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>

                    <Box sx={{ mb: 2 }}>
                        <InputLabel>Partner Name</InputLabel>
                        <Select
                            fullWidth
                            displayEmpty
                            size="small"
                            multiple
                            value={form.partner_id}
                            onChange={(val: any) => handleChangeForm('partner_id', val)}
                            renderValue={val => val.length > 0 ? renderSelect(val, partnerList) : ''}
                        >
                            {partnerList.map((item: any, index: number) => (
                                <MenuItem key={index} value={item.value}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={6}>
                                            {item.label}
                                        </Grid>
                                        <Grid item xs={6} justifyContent="end" textAlign="end">
                                            {form.partner_id.includes(item.value) && (
                                                <CheckIcon fontSize="small" />
                                            )}
                                        </Grid>
                                    </Grid>
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 3 }}>
                    <Button onClick={handleClose} variant="outlined" type="button">Cancel</Button>
                    <LoadingButton
                        loading={loading}
                        loadingPosition="start"
                        startIcon={<Save />}
                        onClick={onSubmit}
                        variant="contained"
                    >
                        Submit
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default CashInExportDialog
