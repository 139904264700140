import { AppBar, IconButton, Toolbar, Typography } from '@mui/material';
import { Menu, MenuOpen } from '@mui/icons-material';
import ProfileMenu from './ProfileMenu';
import { getDataCurrent } from '../../utils/token';

export default function Header({ handleDrawerToggle, open, history }: any) {
  return (
    <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start">
          {open ? <MenuOpen /> : <Menu />}
        </IconButton>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          PAYMENT SYSTEM
        </Typography>
        <Typography variant="h6" noWrap component="div">
          {getDataCurrent().username}
        </Typography>
        <ProfileMenu history={history} />
      </Toolbar>
    </AppBar>
  );
}
