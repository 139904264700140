import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LoadingButton from '@mui/lab/LoadingButton';
import cashOut from "../../redux/actions/cash-out";
import { useAppDispatch, useAppSelector } from '../../redux/store';
import { Box, Grid, OutlinedInput } from '@mui/material';
import Typography from '@mui/material/Typography';
import DataTable from '../../components/table/DataTable';
import { cashinInvoiceColumns } from './models/columns';
import { Search } from '@mui/icons-material';
import { debounce } from 'lodash';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { alertError } from '../../utils/alert';

const CashinInvoiceDialog = ({ open, handleClose, partnerId, id, fetchList }: any) => {
  const [data, setData] = React.useState<any[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [loadingSubmit, setLoadingSubmit] = React.useState(false);
  const [listSelected, setListSelected] = React.useState<any[]>([]);

  const dispatch = useAppDispatch();

  const fetchData = async (currParams: any) => {
    const params = {
      partner_id: partnerId,
      ...currParams,
    }
    setLoading(true);
    try {
      const res: any = await dispatch(cashOut.fetchListInvoice(params));

      setData(res.data);
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    } finally {
      setLoading(false);
    }
  }

  React.useEffect(() => {
    if (open) fetchData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const onSelectTable = (values: any[]) => setListSelected(values);

  const handleSearch = debounce((e) => fetchData({ search: e.target.value }), 500);

  const onClose = () => {
    setData([]);
    setListSelected([]);
    handleClose();
  }

  const handleSubmit = async () => {
    setLoadingSubmit(true);
    const payload = {
      cash_out_id: id,
      cash_in_item_ids: listSelected,
    }

    try {
      const res: any = await dispatch(cashOut.addInvoice(payload));
      fetchList();
      onClose();
    } catch (error: any) {
      alertError(error?.data?.message || 'Maaf, terjadi kesalahan');
    } finally {
      setLoadingSubmit(false);
    }
  }

  return (
    <Dialog
      open={open}
      maxWidth="md"
      fullWidth
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description">
      <DialogTitle id="alert-dialog-title">
        <Grid container spacing={2}>
          <Grid item sm={8}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <ArrowBackIcon style={{ marginRight: 12, cursor: 'pointer' }} onClick={onClose} />
              ADD INVOICE
            </div>
          </Grid>
          <Grid item sm={4}>
            <OutlinedInput
              fullWidth
              onChange={handleSearch}
              placeholder="Search"
              endAdornment={<Search color="disabled" />}
              size="small"
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Box mb={2}>
          <DataTable
            columns={cashinInvoiceColumns()}
            data={data}
            loading={loading}
            isPagination={false}
            height={'40vh'}
            isSelection
            onSelect={(val) => onSelectTable(val)}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ px: 3, pb: 3 }}>
        {listSelected.length > 0 && (
          <Typography variant="subtitle1" component="p" style={{ marginRight: 24 }} color="primary">
            Terpilih: {listSelected.length}
          </Typography>
        )}
        <LoadingButton loading={loadingSubmit} type="button" variant="contained" onClick={handleSubmit}>
          Pilih
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CashinInvoiceDialog;
