// get property object from dot notation
export function getProperty(propertyName: string, object: any) {
    let parts = propertyName.split(".")
    let property = object;
    for (let i = 0; i < parts.length; i++) {
        if (!property) {
            break
        }
        property = property[parts[i]];
    }

    return property;
}
