import baseInitState from '../baseInitState';
import baseReducer from '../baseReducer';
import { actionType } from '../../actions/cash-out';

const initState = {
  ...baseInitState,
  dataQR: '',
  isLoadingGenerate: false,
  dataItems: [],
  isLoadingItems: false,
};

export default function reducer(state = initState, action: any) {
  switch (action.type) {
    case actionType('generateQRCode').request:
      return {
        ...state,
        isLoadingGenerate: true,
      };
    case actionType('generateQRCode').success:
      return {
        ...state,
        dataQR: action.response.data?.qr,
        isLoadingGenerate: false,
      };
    case actionType('generateQRCode').error:
      return {
        ...state,
        isLoadingGenerate: false,
      };
    case actionType('fetchItems').request:
      return {
        ...state,
        isLoadingGenerate: true,
      };
    case actionType('fetchItems').success:
      return {
        ...state,
        dataItems: action.response?.data || [],
        isLoadingItems: false,
      };
    case actionType('fetchItems').error:
      return {
        ...state,
        isLoadingGenerate: false,
      };

    default:
      return baseReducer(state, action, actionType);
  }
}
