import {yupResolver} from "@hookform/resolvers/yup"
import {object, string} from "yup"

export interface ILoginForm {
    email: string,
    password: string
}

export const defaultValues = {
    email: '',
    password: ''
}

export const loginResolver = yupResolver(
    object({
        email: string().required('Required field'),
        password: string().required('Required field'),
    })
)
