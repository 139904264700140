import { BorderColor, Delete } from '@mui/icons-material';
import { Grid, Tooltip } from '@mui/material';
import { GridColDef } from '@mui/x-data-grid';
import { formatDateTime } from '../../../../utils/time';

export const columns = (
  role: any,
  handleEdit: (data: any) => void,
  handleDelete: (data: any) => void,
): GridColDef[] => {
  return [
    { field: 'name', headerName: 'Name', minWidth: 200, flex: 1 },
    { field: 'email', headerName: 'Email', minWidth: 250, flex: 1 },
    { field: 'role_name', headerName: 'Role', minWidth: 200, flex: 1 },
    {
      field: 'last_login',
      headerName: 'Last Login',
      minWidth: 200,
      flex: 1,
      renderCell: ({ value }) => formatDateTime(value),
    },
    {
      field: 'status',
      headerName: 'Status',
      minWidth: 200,
      flex: 1,
      renderCell: (data) => (data.row.status ? 'Active' : 'Non Active'),
    },
    {
      field: 'action',
      headerName: 'Action',
      sortable: false,
      filterable: false,
      renderCell: (data) => (
        <Grid container>
          {role.edit && (
            <Tooltip title="Edit" sx={{ mr: 1 }} onClick={() => handleEdit(data.row)}>
              <BorderColor fontSize="small" className="pointer" color="primary" />
            </Tooltip>
          )}
          {role.delete && (
            <Tooltip title="Delete" onClick={() => handleDelete(data.row)}>
              <Delete fontSize="small" className="pointer" color="error" />
            </Tooltip>
          )}
        </Grid>
      ),
    },
  ];
};
