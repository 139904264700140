import {History, Visibility} from "@mui/icons-material"
import {Chip, Grid, Tooltip} from "@mui/material"
import {GridColDef} from "@mui/x-data-grid"
import {formatMoney} from "../../../utils/string"
import {formatDateTime} from "../../../utils/time"

export const cashInColumns = (handleHistory: (data: any) => void, handleDetail: (data: any) => void): GridColDef[] => {
    return [
        {
            field: 'action',
            headerName: 'Action',
            width: 100,
            sortable: false,
            filterable: false,
            renderCell: (data) => (
                <Grid container alignItems={"center"}>
                    <Tooltip title="Detail" sx={{mr: 1}} onClick={() => handleDetail(data.row)}>
                        <Visibility fontSize="small" className="pointer" color="primary"/>
                    </Tooltip>
                    <Tooltip title="History" onClick={() => handleHistory(data.row)}>
                        <History fontSize="small" className="pointer" color="info"/>
                    </Tooltip>
                </Grid>
            )
        },
        {
            field: 'payment_status',
            headerName: 'Cash In Status',
            width: 150,
            renderCell: ({value}) => <Chip size="small" sx={{textTransform: 'capitalize'}}
                                           label={value.replace(/_/g, ' ')}/>
        },
        {field: 'payment_provider', headerName: 'Payment Provider', width: 150},
        {field: 'payment_channel', headerName: 'Payment Channel', width: 150},
        {field: 'invoice_number', headerName: 'Invoice Number', minWidth: 420},
        {field: 'grand_total', headerName: 'Grand Total', width: 150, renderCell: ({value}) => formatMoney(value)},
        {field: 'admin_fee', headerName: 'Cash In Fee', width: 100, renderCell: ({value}) => formatMoney(value)},
        {field: 'product_fee', headerName: 'Transaction Fee', width: 100, renderCell: ({value}) => formatMoney(value)},
        {field: 'delivery_fee', headerName: 'Delivery Fee', width: 100, renderCell: ({value}) => formatMoney(value)},
        {field: 'product_name', headerName: 'Product Name', width: 150},
        {field: 'create_date', headerName: 'Created Date', width: 200, renderCell: ({value}) => formatDateTime(value)},
        {
            field: 'settlement_date',
            headerName: 'Settlement Date',
            width: 200,
            renderCell: ({value}) => formatDateTime(value)
        },
        {field: 'company_name', headerName: 'Company Name', width: 250},
        {field: 'is_outstanding_cash_out', headerName: 'Outstanding', width: 100, renderCell: ({ value }) => value ? 'Yes' : 'No'},
        {field: 'customer_name', headerName: 'Customer Name', width: 150},
        // {field: 'batch_number_cash_out', headerName: 'Batch', width: 200, renderCell: ({ value }) => value || '-'},
    ]
}

export const cashInHistoryColumns = (): GridColDef[] => {
    return [
        {field: 'payment_provider', headerName: 'Payment Provider', flex: 1, minWidth: 150},
        {field: 'payment_channel', headerName: 'Payment Channel', flex: 1, minWidth: 150},
        {field: 'virtual_account_number', headerName: 'Virtual Account Number', flex: 1, minWidth: 200},
        {
            field: 'created_at',
            headerName: 'Date',
            minWidth: 200,
            flex: 1,
            renderCell: ({value}) => formatDateTime(value)
        },
        {
            field: 'payment_status',
            headerName: 'Payment Status',
            flex: 1, minWidth: 150,
            renderCell: ({value}) => <Chip size="small" sx={{textTransform: 'capitalize'}}
                                           label={value.replace(/_/g, ' ')}/>
        },
    ]
}

export const cashInDetailColumns = (): GridColDef[] => {
    return [
        {field: 'partner_name', headerName: 'Partner Name', flex: 1, minWidth: 250},
        {field: 'item_name', headerName: 'Item Name', flex: 1, minWidth: 180},
        {field: 'ref_invoice_number', headerName: 'Invoice Number Item', flex: 1, minWidth: 250},
        {
            field: 'amount',
            headerName: 'Total',
            flex: 1,
            minWidth: 150,
            renderCell: ({value}) => formatMoney(value)
        },
        // {
        //     field: 'settlement_date',
        //     headerName: 'Settlement Date',
        //     flex: 1,
        //     minWidth: 180,
        //     renderCell: ({value}) => formatDateTime(value)
        // },
        {
            field: 'reconciled_date',
            headerName: 'Reconciled Date',
            flex: 1,
            minWidth: 180,
            renderCell: ({value}) => formatDateTime(value)
        },
        {
            field: 'disbursement_date',
            headerName: 'Disbursement Date',
            flex: 1,
            minWidth: 180,
            renderCell: ({value}) => formatDateTime(value)
        },
        {
            field: 'is_outstanding',
            headerName: 'Outstanding',
            flex: 1,
            minWidth: 250,
            renderCell: ({value}) => value ? 'Yes' : 'No'
        },
        {
            field: 'batch_number_cash_out',
            headerName: 'Batch',
            flex: 1,
            minWidth: 250,
        },
        { field: 'note', headerName: 'Note', flex: 1, minWidth: 200, renderCell: ({ value }) => value || '-' },
        {
            field: 'status',
            headerName: 'Status',
            flex: 1, minWidth: 200,
            renderCell: ({value}) => value ?
                <Chip size="small" sx={{textTransform: 'capitalize'}} label={value.replace(/_/g, ' ')}/> : '-'
        },
    ]
}
