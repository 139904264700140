import {yupResolver} from "@hookform/resolvers/yup"
import {array, number, object, string} from "yup"

export interface IProductNewForm {
    product_name: string,
    code: string,
    company_id: string,
    provider: Array<any>
}

export const defaultValues = {
    product_name: '',
    code: '',
    company_id: '',
    provider: [],
    status: true,
}

const providerChannel = object({
    provider_channel_id: number().transform(value => (isNaN(value) ? undefined : value)).required('Required field'),
    fee_fix_value: string().required('Required field'),
    fee_percentage: string().required('Required field'),
});

const provider = object({
    provider_id: number().transform(value => (isNaN(value) ? undefined : value)).required('Required field'),
    provider_channel: array().of(providerChannel),
});

export const resolver = yupResolver(
    object({
        product_name: string().required('Required field'),
        code: string().required('Required field').min(4, 'Minimum 4 characters'),
        company_id: number().transform(value => (isNaN(value) ? undefined : value)).required('Required field'),
        provider: array().of(provider),
    })
)
