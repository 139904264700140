import moment from "moment";

export const formatSla = (unix_timestamp: number) => {
    let days = Math.floor(unix_timestamp / 3600 / 24)
    let hours = Math.floor(unix_timestamp / 3600 % 24)
    let minutes = Math.floor((unix_timestamp % 3600) / 60)
    let seconds = unix_timestamp % 60
    if (days === 1 && hours === 0) {
        days = 0
        hours = 24
    }
    if (days !== 0) {
        return `${days}d ${hours}h ${minutes}m ${seconds}s`
    } else if (hours !== 0) {
        return `${hours}h ${minutes}m ${seconds}s`
    } else if (minutes !== 0) {
        return `${minutes}m ${seconds}s`
    }
    return `${seconds}s`
}

export const formatDate = (date: string) => {
    if (!date || date === '0') {
        return '-'
    }
    return moment(date).format('DD MMMM YYYY')
}

export const formatDateTime = (date: string) => {
    if (!date || date === '0') {
        return '-'
    }
    return moment(date).format('DD MMMM YYYY HH:mm')
}
