import axios from 'axios';
import { alertError } from '../utils/alert';
import { getToken, isAuth, removeAll } from '../utils/token';

const httpService = (token?: string) => {
  const service = axios.create();
  service.defaults.timeout = 20000;
  service.defaults.timeoutErrorMessage = 'Request timeout exceeded';

  service.interceptors.request.use(
    function (config) {
      if (!navigator.onLine) {
        alertError('No internet connection.');
        return Promise.reject();
      }
      const currentToken = token || getToken();
      if (!!currentToken) config.headers.Authorization = currentToken;
      return config;
    },
    function (error) {
      return Promise.reject(error);
    },
  );

  service.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === 401 && isAuth()) {
        removeAll();
        return (window.location.href = '/login');
      } else if (error?.message === 'Network Error') {
        alertError('Server error');
      } else {
        console.log(error?.message);
      }
      return Promise.reject(error);
    },
  );
  return service;
};

export default httpService;
