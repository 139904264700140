import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {FormProvider, useForm} from 'react-hook-form';
import {InputField} from '../../components/fields/RenderField';
import {alertError, alertSuccess} from '../../utils/alert';
import LoadingButton from '@mui/lab/LoadingButton';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {IRejectForm, rejectDefaultValues, rejectResolver} from './models/form';
import cashOut from '../../redux/actions/cash-out';
import {Box, InputLabel, List, ListItem} from '@mui/material';

const RejectDialog = ({open, handleClose, data, list, type}: any) => {
    const methods = useForm<IRejectForm>({resolver: rejectResolver})

    const loading = useAppSelector(state => state.cashOut.isLoadingSubmit)
    const params = useAppSelector(state => state.cashOut.params)
    const cashOutList = useAppSelector(state => state.cashOut.dataList)
    const dispatch = useAppDispatch()

    const onSubmit = async (value: any) => {
        const formData = {
            cash_out_transaction_id: type === 'bulk' ? list : [data?.id],
            otp_code: value.otp_code,
            reason: value.reason
        }
        try {
            await dispatch(cashOut.reject(formData))
            alertSuccess(`Berhasil ditolak`).then(
                () => {
                    handleClose()
                    dispatch(cashOut.fetchList(params))
                }
            );
        } catch (error: any) {
            alertError(error?.data?.message || 'Maaf, terjadi kesalahan')
        }
    }
    React.useEffect(() => {
        if (data?.id && open === true) {
            methods.reset(data)
        } else {
            methods.reset(rejectDefaultValues)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open])

    const batchList = () => {
        return cashOutList.filter((item: any) => {
            return list.includes(item.id)
        })
    }

    return (
        <div>
            <Dialog
                open={open}
                maxWidth="xs"
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <form onSubmit={methods.handleSubmit(onSubmit)}>
                    <DialogTitle id="alert-dialog-title">
                        Reject
                    </DialogTitle>
                    <DialogContent>
                        <FormProvider {...methods}>
                            {type === 'bulk' &&
                                <Box sx={{mb: 1}}>
                                    <InputLabel>Batch Number</InputLabel>
                                    <List dense disablePadding>
                                        {batchList().map((item: any, idx: number) =>
                                            <ListItem disablePadding
                                                      key={idx}>{`${idx + 1}. ${item.batch_number} - ${item.partner_name}`}</ListItem>)}
                                    </List>
                                </Box>}
                            {type === 'one' && <InputField name="batch_number" label="Batch Number" disabled/>}
                            <InputField name="otp_code" label="Verification Code"/>
                            <InputField name="reason" label="Reason Reject" rows={3}/>
                        </FormProvider>
                    </DialogContent>
                    <DialogActions sx={{px: 3, pb: 3}}>
                        <Button onClick={handleClose} variant="outlined" type="button">Cancel</Button>
                        <LoadingButton loading={loading} type="submit" color="error" variant="contained">
                            Reject
                        </LoadingButton>
                    </DialogActions>
                </form>
            </Dialog>
        </div>
    );
}

export default RejectDialog
