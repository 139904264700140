const index = {
    isLoading: false,
    isLoadingDetail: false,
    isLoadingSubmit: false,
    isLoadingDelete: false,
    dataAll: [],
    dataList: [],
    dataDetail: null,
    pagination: {
        currentPage: 1,
        lastPage: 1,
        count: 0,
        recordPerPage: 5
    },
    params: {
        page: 1,
        limit: 10,
        order: 'created_at',
        sort: 'desc',
    }
}
export default index;
